export const WELCOME = 'welcome';
export const SPORTS_BETTING_TIPS = 'sports_betting_tips';
export const MATCH_PLAY_BONUS_DEPOSITED = 'match_play_bonus_deposited';
export const BETS = 'bets';
export const FAVORITES = 'favorites';
export const BET_ACCEPTED = 'bet_accepted';
export const BET_RESULTS_SUBMITTED = 'bet_results_submitted';
export const BET_RESULTS_DISPUTED = 'bet_results_disputed';
export const DEPOSIT = 'deposit';
export const WITHDRAWAL_REQUEST = 'withdrawal_request';
export const KYC_SUCCESSFULLY_VERIFIED = 'kyc_successfully_verified';
export const KYC_VERIFICATION_FAILED = 'kyc_verification_failed';
export const KYC_STATUS = 'kyc_status';
export const TOURNAMENT_REGISTRATION = 'tournament_registration';
export const PLAYER_FOLLOW = 'player_follow';
export const TOURNAMENT_MATCH = 'tournament_match';
export const WEBINAR_AND_TRAINING = 'webinar_and_training';
export const SHARE_BETS = 'share_bets';
export const CRYPTOCURRENCY_TRANSACTION = 'cryptocurrency_transaction';
export const CONNTEST_ENTERED = 'contest_entered';
export const CONTEST_COMPLETED = 'contest_completed';
export const PARLAY = 'parlay';
