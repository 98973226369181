import httpService from './http.service';

const attempt = (body) => httpService
  .post('/kyc/attempt', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const status = () => httpService
  .get('/kyc/status')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  attempt,
  status,
};
