import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Box, Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import CommonStyle from 'styles/common';

import { mitekPageState } from 'utils/mitek';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#63C4C6',
    '&$checked': {
      color: '#63C4C6',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: '2',
  },
  mainTitle: {
    font: '18px SofiaPro-SemiBold',
  },
  description: {
    font: '13px SofiaPro',
    color: 'grey',
  },
  mainImage: {
    width: '80%',
    display: 'block',
    margin: '30px auto',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
  checkBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '10px',
    '& span': {
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '14px',
      fontWeight: 'Bold',
    },
  },
  stepsWizard: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px auto',
  },
  stepsWizardBackgroundBar: {
    position: 'absolute',
    width: '98%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#AEAEAE',
    zIndex: '-1',
  },
  stepsWizardBackgroundBarActive: {
    position: 'absolute',
    width: '0%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#29ced3',
    zIndex: '-1',
  },
  stepsNumber: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#AEAEAE',
    color: '#808080',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  stepsNumberActive: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#29ced3',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '22px',
  },
}));

const MitekSelect = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();

  // mitekPageState.init()
  mitekPageState.resetSession();

  const [documentType, setDocumentType] = useState(mitekPageState.document_type);
  const [issuedCountry, setIssuedCountry] = useState(mitekPageState.location);

  const handleChange = async (event, name) => {
    if (name === 'documentType') {
      setDocumentType(event.target.name);
    } else if (name === 'issuedCountry') {
      setIssuedCountry(event.target.name);
    }
  };

  const handleSubmit = async () => {
    try {
      if (documentType && issuedCountry) {
        mitekPageState.session.document_type = documentType;
        mitekPageState.session.location = issuedCountry;
        history.push('/mitek_step1_1');
      }
    } catch (e) {
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/mitek_start" />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography component="h3" className={classes.title}>
            STEP 1
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box mt={2} mb={2}>
            <Typography className={classes.mainTitle}>
              Select Document Type
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="subtitle2" className={classes.description}>
              What type of document would you like to submit?
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="driving"
                  checked={documentType === 'driving'}
                  onChange={(e) => handleChange(e, 'documentType')}
                />
              )}
              label="Driver's License"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="id"
                  checked={documentType === 'id'}
                  onChange={(e) => handleChange(e, 'documentType')}
                />
              )}
              label="Government ID"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="passport"
                  checked={documentType === 'passport'}
                  onChange={(e) => handleChange(e, 'documentType')}
                />
              )}
              label="Passport"
              className={classes.checkBox}
            />
          </Box>
          <Box mt={4} mb={2}>
            <Typography className={classes.mainTitle}>
              Select Country of Issue
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="subtitle2" className={classes.description}>
              Which country issued your document?
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="usa_or_canada"
                  checked={issuedCountry === 'usa_or_canada'}
                  onChange={(e) => handleChange(e, 'issuedCountry')}
                />
              )}
              label="USA or Canada"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="rest"
                  checked={issuedCountry === 'rest'}
                  onChange={(e) => handleChange(e, 'issuedCountry')}
                />
              )}
              label="Rest of the world"
              className={classes.checkBox}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box className={classes.stepsWizard}>
            <Box className={classes.stepsWizardBackgroundBar}></Box>
            <Box className={classes.stepsWizardBackgroundBarActive}></Box>
            <Box className={classes.stepsNumber}>
              1
            </Box>
            <Box className={classes.stepsNumber}>
              2
            </Box>
            <Box className={classes.stepsNumber}>
              3
            </Box>
          </Box>
          <OutlineButton type="submit" disabled={!documentType || !issuedCountry} onClick={handleSubmit}>
            Next
          </OutlineButton>
        </Box>
      </Box>
    </Page>
  );
};

MitekSelect.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MitekSelect);
