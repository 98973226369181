class MitekPageState {
  constructor() {
    this.license = 'eyJzaWduYXR1cmUiOiJYUjYyZzUzOUUxMS9WV1Axbi8xUC9pckpFa1ZZdjNqRU9tUUpQR2dGbjVta1hEYjIvZUN0YzNZd2J5RDYzUUFoaWtWMk13d3ZjYmdwM3dxdHJaeXBiQ0VtY2o5aWd4TWpOc0Y4a0FmMGZaT1lnZ1VvU0I4aWNnemh2ZDc2S3NqbVdwZDB3eHM3ek9aMUJmM2taVHJOcWZDWU1UU3pWbEExREhKUzE3T25ya2FDQjB2dE84aHVOSzRLa0taNDRDY1RyNy8yVFhpazdBR1gxSm9JS1JLTmdvaVBGaGRQMjRqNTFPTXdRa3p0RXp6bTBhd1c5aXN4RDRhd0srbE5tZnlDYzlXSDRqS3lnMHkxMjdBTWNjVE1TNTZrWkVZVGZRY0FHNDZ6QjBuakpuSllNdGFWUkp3NWlzL2tJa21YWVg0UkFCelFzZGgxbjBvcWc2NlRTNkhneUE9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI0LTAyLTI5IiwiZmVhdHVyZXMiOlsiZ2VuZXJpYyIsImZhY2UiLCJpZCIsImJhcmNvZGUiLCJuZmMiLCJvZGMiLCJvZGUiXSwiYXBwbGljYXRpb25JZGVudGlmaWVyIjp7ImFuZHJvaWQiOlsiY29tLnplbnNwb3J0cy56ZW5zcG9ydHMiXSwiaW9zIjpbImNvbS56ZW5zcG9ydHMuemVuc3BvcnRzc3RhZ2luZyIsImNvbS56ZW5zcG9ydHMuemVuc3BvcnRzIl0sIndlYiI6WyJsb2NhbGhvc3Q6MzAwMCIsInN0Z2FwcC56ZW5zcG9ydHMuY29tIiwiYXBwLnplbnNwb3J0cy5jb20iXX0sImdwbyI6IjE2ZCIsIm9yZ2FuaXphdGlvbiI6Ik1pdGVrIFN5c3RlbXMgSW5jLiIsInZlcnNpb24iOiIxLjAifX0='; 
    

    this.currentSDKResult = Object.create(null)
    this.pageElementOpts = {
      showAutocaptureCancelButton: true
    }
    this.mitekSDKPath = location.origin + '/mitekSDK/'

    this.firstMessageDuration = 1500
    this.autoCaptureAttemptTimer = null
    this.manualCaptureAttemptTimer = null
    this.selectors = Object.create(null) //dom selectors will be added on page load seciton.
    //Component preload config
    this.componentPreloadConfig = {
      preloadComponents: [
        'SELFIE',
        'BARCODE',
        'DOCUMENTS',
      ], // what compoments to preload for quick UX. 'ALL' / 'SELFIE', 'BARCODE', 'DOCUMENTS'. Note: DOCUMENTS also includes checks and generic documents.
      mitekSDKPath: this.mitekSDKPath,
      options: { license: this.license }
    }
    //auto-capture CAPTURE_AND_PROCESS_FRAME config object
    this.autoCaptureOptions = {
      qualityPercent: 100,   // value between 50 to 100 for image quality 100 is no compression.
      hintFrequencyMS: 1000, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
      hintAriaLive: 0, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
      faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
      hintMessageSize: 1,  // set the size of the hint message (1=small / 2=medium / 3=large)
      disableSmileDetection: false,  // disable smile detection
      //disableMirroring: false, //use this when you know a devices is reporting as a desktop and mirroring needs to be turned off for doc captures. Only use this if you already know its a desktop device.
      disablePerpendicularCapture: true, //perpendicular capture is a boolean value defaults to false if not provided. Forces document to follow device orientation.
      // videoContainerId: 'customdiv', // (optionally) embed the video in a custom HTML container
      videoRecordingEnabled: false, // (optionally) record the video session / returned as a dataURL
      audioRecordingEnabled: false, // (optionally) also record audio / only applies if videoRecordingEnabled = true)
      videoQuality: 30, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
      videoRecordingMessage: 'Recording', // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
      license: this.license
    }
    this.manualCaptureOptions = {
      qualityPercent: 100, // Value between 50 and 100 for image quality.
      disablePerpendicularCapture: false,
      license: this.license
    }
    this.voiceCaptureOptions = {
      phrase: this.integratorPhraseSelection?.value,
      license: this.license
    }
    this.directScienceOptions = {
      frame: {}, //this will be overriden at method call.
      qualityPercent: 100,
      license: this.license
    }

    this.autoCaptureHints = Object.create(null) //set later in load examples.
    this.manualCaptureHints = Object.create(null) //for manual and Direct evaluation.


    this.timer = null;
    this.recentHint = null;
    
    this.autoCaptureHints = {
      MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
      MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
      MISNAP_AXIS_ANGLE: 'Hold Phone Upright',
      MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
      MISNAP_HEAD_TOO_FAR: 'Get Closer',
      MISNAP_STAY_STILL: 'Hold Still',
      MISNAP_SUCCESS: 'Success',
      MISNAP_STOP_SMILING: 'Stop Smiling',
      MISNAP_SMILE: 'Hold a smile',
      MISNAP_READY_POSE: 'Hold it There',
      NO_FACE_FOUND: 'Detecting face please wait',
      MITEK_ERROR_GLARE: 'Reduce Glare',
      MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
      MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
      MITEK_ERROR_FOCUS: 'Hold Steady',
      MITEK_ERROR_MRZ_MISSING: 'Can\'t read every element on the photo page of your passport',
      CV_NO_BARCODE_FOUND: 'Scanning for barcode',
      MITEK_ERROR_TOO_FAR: 'Document Too Far',
      MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
      MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
      MITEK_ERROR_MIN_PADDING: 'Move further away',
      MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
      MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background',
      MITEK_ERROR_BUSY_BACKGROUND: 'Center document on a plain background',
      MITEK_ERROR_SKEW_ANGLE: 'Reduce angle',
      MITEK_ERROR_PERPENDICULAR_DOCUMENT: 'Change orientation of your document',
    };

    this.manualCaptureHints = {
      MITEK_ERROR_FOUR_CORNER: 'We can\'t find the 4 corners of your document.',
      MITEK_ERROR_TOO_DARK: 'There is not enough light on your document.',
      MITEK_ERROR_FOCUS: 'The image is too blurry.',
      MITEK_ERROR_GLARE: 'The image has glare.',
      MITEK_ERROR_MIN_PADDING: 'Move the camera further away from your document.',
      MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document.',
      MITEK_ERROR_SKEW_ANGLE: 'Document is skewed.  Hold camera directly over your document.',
      MITEK_ERROR_LOW_CONTRAST: 'Center document on a dark background.',
      MITEK_ERROR_BUSY_BACKGROUND: 'The background is too busy.  Please use a solid background.',
      MITEK_ERROR_MRZ_MISSING: 'No MRZ found',
      CV_NO_BARCODE_FOUND: 'We were unable to detect the barcode from the back of your license.',
      IMAGE_SMALLER_THAN_MIN_SIZE: 'The image you provided is too small.',
      CORRUPT_IMAGE: 'The image you provided is unreadable.',
      MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
      MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
      MISNAP_HEAD_TOO_FAR: 'Get Closer',
      NO_FACE_FOUND: 'No Face Detected',
      MITEK_ERROR_PERPENDICULAR_DOCUMENT: 'Change orientation of your document',
    };

    this.customMessages = {
      VOICE: {
        firstMessage: 'Tap mic button to start, then repeat the phrase on screen.',
        readPhraseMessage: 'Repeat the phrase above loud and clear',
        speakLouderMessage: 'Please repeat phrase louder in a quiet room'
      },
      DL_FRONT: {
        firstMessage: 'Please - Center document on a dark background',
        fourCornerMessage: 'Center document on a dark background'
      },
      PDF417_BARCODE: {
        firstMessage: 'Center barcode',
        fourCornerMessage: 'Scanning for barcode'
      },
      QR_BARCODE: {
        firstMessage: 'Center barcode',
        fourCornerMessage: 'Scanning for barcode'
      },
      PASSPORT: {
        firstMessage: 'Center photo page on a dark background',
        fourCornerMessage: 'Center photo page on a dark background'
      },
      SELFIE: {
        firstMessage: 'Place face in oval',
        fourCornerMessage: 'Face not found'
      },
      CHECK_FRONT: {
        firstMessage: 'Center Check Front on a dark background',
        fourCornerMessage: 'Scanning for Check Front'
      },
      CHECK_BACK: {
        firstMessage: 'Center Check Back on a dark background',
        fourCornerMessage: 'Scanning for Check Back'
      },
      DOCUMENT: {
        firstMessage: 'Center document on a dark background',
        fourCornerMessage: 'Center document on a dark background'
      }
    };

    this.resetSession();
  }

  resetSession() {
    this.session = {
      document_type: '',
      location: '',
      front: null,
      back: null,
      selfie: null,
      submitted: false,
    };
  }

  getDocumentType(side) {
    if (this.session.document_type == 'passport') {
      return 'PASSPORT';
    }
    if (this.session.document_type == 'driving' && this.session.location == 'usa_or_canada' && side == 'back') {
      return 'PDF417_BARCODE';
    }
    return 'DL_FRONT';
  }

  addCancelButton(parentDomElement = document.getElementById("mitekDisplayContainer")) {
    let buttonEl = document.createElement('button');
    buttonEl.setAttribute('id', 'mitekCancelButton');
    buttonEl.onclick = function (e) {
      mitekScienceSDK.cmd('SDK_STOP');
      clearTimeout(mitekPageState.autoCaptureAttemptTimer);
    };
    parentDomElement.appendChild(buttonEl);
  }

  updateSDKResult(currentSDKResult) {
    this.currentSDKResult = { ...currentSDKResult }
  }
  //this just toggles the property in default options
  toggleVideoRecording() {
    mitekPageState.autoCaptureOptions.videoRecordingEnabled = !mitekPageState.autoCaptureOptions.videoRecordingEnabled
  }
  //toggles the include audio in video.
  toggleAudioRecording() {
    mitekPageState.autoCaptureOptions.audioRecordingEnabled = !mitekPageState.autoCaptureOptions.audioRecordingEnabled
  }

  auto_capture(document_type, onSuccess, onFailure) {

    mitekPageState.recentHint = null;
    mitekPageState.autoCaptureAttemptTimer = null;


    // camera started
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', function (result) {
      //cameraDisplayStarted on auto.
      // show the first initial hint message
      let firstMessage = mitekPageState.customMessages[document_type].firstMessage;

      //Hint message command example with new options object
      mitekScienceSDK.cmd('SHOW_HINT', { options: { hintText: firstMessage, hintDuration: mitekPageState.firstMessageDuration } });
    });
    //auto
    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function autoCapture(result) {
      try {
        mitekPageState.recentHint = result.key;
        // SELFIE ONLY
        if (result.actionType === 'SELFIE') {
          let guideElement = document.getElementById("mitekGuide");
          // turn oval green if head is in guide
          if (result.key === 'MISNAP_SMILE'
            || result.key === 'MISNAP_STOP_SMILING'
            || result.key === 'MISNAP_READY_POSE') {
            guideElement && guideElement.classList.add('active');
          } else {
            guideElement && guideElement.classList.remove('active');
          }
          if (mitekPageState.recentHint !== null) {
            mitekScienceSDK.cmd('SHOW_HINT', mitekPageState.autoCaptureHints[mitekPageState.recentHint]);
          }
        } // NOT SELFIE, EVERYTHING ELSE for feedback
        else {
          if (mitekPageState.recentHint !== null) {
            let hintMsg = mitekPageState.autoCaptureHints[mitekPageState.recentHint];
            // use a custom message for four corners not found
            if (mitekPageState.recentHint === 'MITEK_ERROR_FOUR_CORNER') {
              hintMsg = mitekPageState.customMessages[document_type].fourCornerMessage;
            }

            mitekScienceSDK.cmd('SHOW_HINT', hintMsg);
          }
        }
        //recommendation use try catch in your callbacks to quickly identify any shown errors related to callbacks.
      } catch (error) {
        throw new Error("AutocaptureProcessingFeedback - " + error)
      }
    });

    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', function (result) {
      /** Example: show cancel button on capture screen**/
      if (mitekPageState.pageElementOpts.showAutocaptureCancelButton) {
        // addCancelButton();
      }


      //setup a timer after frame processing
      mitekPageState.autoCaptureAttemptTimer = setTimeout(function () {
        // console.log('index.html - timeout');
        clearTimeout(mitekPageState.autoCaptureAttemptTimer);
        mitekScienceSDK.cmd('SDK_STOP');

      }, 1000 * 5)

    });
    //auto
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result) {
      //sample state update.
      mitekPageState.updateSDKResult(result);
      // console.log('index.html - Auto capture result', result);
      clearTimeout(mitekPageState.timer);
      clearTimeout(mitekPageState.autoCaptureAttemptTimer);

      // showCapturedVideo(result);
      if (result.response.status === 'failure') {
        onFailure(result);
        // showLastFailedImage();
      } else {
      //   showCaptureResult(result);
        onSuccess(result);
      }
    });

    mitekScienceSDK.on('SDK_ERROR', function (err) {

      clearTimeout(mitekPageState.timer);
      console.log('mitek error', err);
      onFailure();
    });

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: document_type,
      mitekSDKPath: mitekPageState.mitekSDKPath,
      options: mitekPageState.autoCaptureOptions
    });
  }

  manual_capture(document_type, onSuccess, onFailure) {

    mitekPageState.recentHint = null;
    mitekPageState.manualCaptureAttemptTimer = null;

    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', function (result) {
      // console.log('manual - CAMERA_DISPLAY_STARTED')
      mitekScienceSDK.cmd('SHOW_HINT', 'Hold Still');
    });

    mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', function (hintMessageObject) {
      // console.log('manual - FRAME_PROCESSING_FEEDBACK')
      mitekScienceSDK.cmd('SHOW_HINT', 'Hold Still');
    });

    mitekScienceSDK.on('IMAGE_CAPTURED', function (result) {
      // console.log('manual - IMAGE_CAPTURED')
    });

    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', function () {
      // console.log('manual - FRAME_PROCESSING_STARTED')
      //setup a timer after frame processing
      mitekPageState.manualCaptureAttemptTimer = setTimeout(function () {
        // console.log('index.html - timeout');
        clearTimeout(mitekPageState.manualCaptureAttemptTimer);
        mitekScienceSDK.cmd('SDK_STOP');
      }, 1000 * 3)
    });

    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', function (result) {
      // console.log('manual - FRAME_CAPTURE_RESULT')
      //sample state update.
      mitekPageState.updateSDKResult(result);
      // console.log('index.html - Auto capture result', result);
      clearTimeout(mitekPageState.timer);
      clearTimeout(mitekPageState.manualCaptureAttemptTimer);

      // console.log("index.html - Manual capture result", result);
      // showCaptureResult(result);
      onSuccess(result);
    });
    mitekScienceSDK.on('SDK_ERROR', function (err) {
      console.log('mitek error', err);
      onFailure();
    });

    console.log('trigger manual capture');
    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: document_type,
      mitekSDKPath: mitekPageState.mitekSDKPath,
      options: mitekPageState.autoCaptureOptions
    });
  }

  init() {
    // console.log(mitekPageState.componentPreloadConfig)
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', mitekPageState.componentPreloadConfig)
  }
}

const mitekPageState = new MitekPageState();
window.mitekPageState = mitekPageState;

//Component preload
// mitekScienceSDK.on('SDK_ERROR', function (err) { // we add an SDK error  listener here in case our component preload has an issue.
//   console.log('mitek error', err)
// });
window.addEventListener( 'load', function() {
  // console.log('loading point for mitek')
  // mitekPageState.init()
})

export {
  mitekPageState,
};