import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 'calc(50vh - 20px)',
    left: 'calc(50vw - 20px)',
    zIndex: 100,
  },
});

const Loading = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
