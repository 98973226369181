import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Button } from '@material-ui/core';
import { usePlayList, usePlaySelectedQuery, useSetTournamentQueryDispatch } from 'hooks/redux/play';
import { getFilterQuery } from 'utils/play.sort';
import PlayBannerImage from 'assets/images/play/play_banner.png';
import CompetitionsVlist from './CompetitionVlist';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: '10px 10px 0 10px',
    margin: '20px 0 15px',
  },
  logoMark: {
    backgroundImage: `url(${PlayBannerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '18px',
    borderRadius: '5px',
    marginBottom: '8px',
    flexDirection: 'column',
  },
  content: {
    fontFamily: 'SofiaPro-Regular',
    color: 'white',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: 0.6,
    paddingBottom: '10px',
  },
  vListWrapper: {
    flex: 1,
    height: '100vh',
  },
  emptyState: {
    textAlign: 'center',
    color: '#aeaeae',
    marginTop: '120px',
    lineHeight: '1.2',
  },
  betButton: {
    width: '166px',
    backgroundColor: '#fff',
    padding: '6px 0px !important',
    '& .MuiButton-label': {
      color: '#52dcdd',
      fontSize: '15px',
      fontFamily: 'SofiaPro-Medium',
      textTransform: 'capitalize',
    },
  },
  competitionCard: {
    margin: '20px 0',
  },
}));

const Competitions = ({ history }) => {
  const classes = useStyles();
  const [options, setOptions] = useState();

  const setTournamentQuery = useSetTournamentQueryDispatch();
  const filter = usePlaySelectedQuery();
  const play = usePlayList(options);

  useEffect(() => {
    if (filter) {
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter),
        filter,
      });
    }
    // eslint-disable-next-line
  }, [filter]);

  const createTournament = () => {
    setTournamentQuery({});
    history.push('/more/play/tournament/create/information');
  };

  return (
    <Box>
      <Box textAlign="center">
        <Typography component="h3" className={classes.title}>
          Play
        </Typography>
      </Box>
      <Box
        className={classes.logoMark}
        display="flex"
      >
        <Typography variant="subtitle1" className={classes.content}>
          What will you play next?
        </Typography>
        <Button
          variant="contained"
          className={classes.betButton}
          onClick={createTournament}
        >
          Create Tournament
        </Button>
      </Box>
      <CompetitionsVlist play={play} />
    </Box>
  );
};

Competitions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Competitions);
