import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

import Page from 'components/Page/Auth';
import SportsGroupImage from 'assets/images/sports-group.png';
import LogoImage from 'assets/images/dashboard/color_logo.png';
import FacebookIconImage from 'assets/images/social/facebook.png';
import TwitterIconImage from 'assets/images/social/twitter.png';
import TelegramIconImage from 'assets/images/social/telegram.png';
import LinkedinIconImage from 'assets/images/social/linkedin.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    minHeight: 'calc(100% - 40px)',
  },
  header: {
    marginBottom: '28px',
  },
  logo: {
    width: '50vw',
  },
  title: {
    color: 'white',
    margin: 0,
    fontSize: '38px',
  },
  subTitle: {
    font: '15px SofiaPro',
    textAlign: 'center',
    color: 'white',
    lineHeight: '20px',
  },
  emailIcon: {
    marginRight: 10,
  },
  button: {
    padding: 10,
    height: 'auto',
    background: '#FFFFFF',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#14133D',
  },
  line: {
    backgroundColor: '#FFFFFF',
    marginTop: '38px',
    width: '100%',
  },
  socialList: {
    display: 'flex',
  },
  socialItem: {
    padding: '0 10px',
  },
  socialIcon: {
    width: '40px',
  },
});

const socials = [
  {
    link: 'https://www.facebook.com/TheZenSports',
    icon: FacebookIconImage,
  },
  {
    link: 'https://twitter.com/zensports',
    icon: TwitterIconImage,
  },
  {
    link: 'https://t.me/thezensports',
    icon: TelegramIconImage,
  },
  {
    link: 'https://www.linkedin.com/company/zensports',
    icon: LinkedinIconImage,
  },
];

const Landing = ({ history }) => {
  const classes = useStyles();

  const handleSignUp = () => {
    history.push('/register');
  };
  return (
    <Page backgroundImage={SportsGroupImage} className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="center" className={classes.header}>
        <img src={LogoImage} alt="logo" className={classes.logo} />
      </Box>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Peer-to-Peer sports betting marketplace. Create and accept sports bets with anyone in the world.
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button className={classes.button} onClick={handleSignUp}>
          <EmailRoundedIcon className={classes.emailIcon} />
          Sign up/Log in via Email
        </Button>
      </Box>
      <hr className={classes.line} />
      <Box className="font-small color-white text-center" mt={4}>
        By creating a new ZenSports account or logging  into your existing ZenSports account, you`re agreeing to ZenSports
        <a className="color-primary" href="https://zensports.com/tos">&nbsp;Terms of Service</a>
        &nbsp;&&nbsp;
        <a className="color-primary" href="https://zensports.com/privacy">Privacy Policy</a>
      </Box>
      <Box display="flex" justifyContent="center" mt={3}>
        <Box className={classes.socialList}>
          {
            socials.map((social) => (
              <Box key={social.link} className={classes.socialItem}>
                <a href={social.link}>
                  <img src={social.icon} alt="icon" className={classes.socialIcon} />
                </a>
              </Box>
            ))
          }
        </Box>
      </Box>
    </Page>
  );
};

Landing.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Landing);
