import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
} from 'react-router-dom';

import PublicRoute from 'routes/PublicRoute';
import PrivateRoute from 'routes/PrivateRoute';
import { getDeviceType } from 'utils';
import { history } from 'store';

// common
import ErrorModal from 'components/Modal/ErrorModal';
import ConfirmModal from 'components/Modal/ConfirmModal';
import SuccessModal from 'components/Modal/SuccessModal';
// mobile dialog
import MobileOnlyDialog from 'components/Dialog/MobileOnlyDialog';

// auth
import LoginPage from 'containers/Auth/Login';
import SignUpPage from 'containers/Auth/SignUp';
import LandingPage from 'containers/Auth/Landing';

// welcome
import WelcomePage from 'containers/Auth/Welcome';
import CountryPage from 'containers/Auth/Country';
import StatePage from 'containers/Auth/State';
import BirthdayPage from 'containers/Auth/Birthday';
import LocationPage from 'containers/Auth/Location';
import ReferralBonusPage from 'containers/Auth/ReferralBonus';
import CasinoPage from 'containers/Auth/Casino';

// Bets
import Bets from 'containers/Dashboard/Bets';
import Esports from 'containers/Dashboard/Bets/Esports';
import TimePeriod from 'containers/Dashboard/Bets/View/TimePeriod';
import UpcomingGames from 'containers/Dashboard/Bets/View/UpcomingGames';
import AllBets from 'containers/Dashboard/Bets/View/AllBets';
import BetsFilter from 'containers/Dashboard/Bets/View/Filter';
import SingleBet from 'containers/Dashboard/Bets/View/SingleBet';
import SelectLeague from 'containers/Dashboard/Bets/Create/SelectLeague';
import SelectEsport from 'containers/Dashboard/Bets/Create/SelectEsport';
import SelectTimePeriod from 'containers/Dashboard/Bets/Create/SelectTimePeriod';
import SelectSeason from 'containers/Dashboard/Bets/Create/SelectSeason';
import SelectGame from 'containers/Dashboard/Bets/Create/SelectGame';
import SelectTeamPlayer from 'containers/Dashboard/Bets/Create/SelectTeamPlayer';
import SelectBetType from 'containers/Dashboard/Bets/Create/SelectBetType';
import SelectBetTime from 'containers/Dashboard/Bets/Create/SelectBetTime';
import FinalSubmit from 'containers/Dashboard/Bets/Create/FinalSubmit';
import Favorites from 'containers/Dashboard/Bets/Favorites';
import EditFavorites from 'containers/Dashboard/Bets/Favorites/EditFavorites';
import FavoritesFilter from 'containers/Dashboard/Bets/Favorites/Filter';
import EditFinalSubmit from 'containers/Dashboard/Bets/Edit/FinalSubmit';
import EditSelectBetTime from 'containers/Dashboard/Bets/Edit/SelectBetTime';
import EditSelectBetType from 'containers/Dashboard/Bets/Edit/SelectBetType';
import EditSelectGame from 'containers/Dashboard/Bets/Edit/SelectGame';
import EditSelectTeamPlayer from 'containers/Dashboard/Bets/Edit/SelectTeamPlayer';
import ParlayCheckout from 'containers/Dashboard/Bets/Parlay/ParlayCheckout';
import ParlaySingle from 'containers/Dashboard/Bets/Parlay/SingleParlay';

// Nav
import MoreMenu from 'containers/Dashboard/More';
import NotifsMenu from 'containers/Dashboard/Notifs';
import Setting from 'containers/Dashboard/More/Setting';
import SettingDisplay from 'containers/Dashboard/More/Setting/Display';
import SettingProfile from 'containers/Dashboard/More/Setting/Profile';
import SelectProfileCountry from 'containers/Dashboard/More/Setting/ProfileCountry';
import SelectProfileState from 'containers/Dashboard/More/Setting/ProfileState';
import SettingNotification from 'containers/Dashboard/More/Setting/Notification';
import SystemRequirements from 'containers/Dashboard/More/Setting/SystemRequirements';
import ReferralBonus from 'containers/Dashboard/More/ReferralBonus';
import ViewReferralBonus from 'containers/Dashboard/More/ReferralBonus/ViewBonus';
import FundsMenu from 'containers/Dashboard/Funds';
import Wallet from 'containers/Dashboard/Funds/Wallet';

// Deposit
import Deposit from 'containers/Dashboard/Funds/Deposit';
import DepositAddDebitCard from 'containers/Dashboard/Funds/Deposit/Debit/AddDebitCard';
import DepositSubmitDebitCard from 'containers/Dashboard/Funds/Deposit/Debit/Submit';
import DepositPaymentCard from 'containers/Dashboard/Funds/Deposit/Debit/Payment';
import DepositWireTransfer from 'containers/Dashboard/Funds/Deposit/WireTransfer';
import DepositInstruction from 'containers/Dashboard/Funds/Deposit/Instruction';
import DepositConfirmation from 'containers/Dashboard/Funds/Deposit/Confirmation';

// Exchange
import Markets from 'containers/Dashboard/Funds/Exchange/Markets';
import Market from 'containers/Dashboard/Funds/Exchange/Market';

// SPORTS Rewards
import Rewards from 'containers/Dashboard/Funds/Rewards';

// Withdraw
import Withdraw from 'containers/Dashboard/Funds/Withdraw';
import WithdrawWireInstructions from 'containers/Dashboard/Funds/Withdraw/Wire/Instructions';
import SelectBankCountry from 'containers/Dashboard/Funds/Withdraw/Wire/WithdrawBankCountry';
import SelectWithdrawCountry from 'containers/Dashboard/Funds/Withdraw/Wire/WithdrawCountry';
import SelectWithdrawState from 'containers/Dashboard/Funds/Withdraw/Wire/WithdrawState';
import WithdrawWireSubmit from 'containers/Dashboard/Funds/Withdraw/Wire/Submit';

import MitekStart from 'containers/Dashboard/Funds/KycVerification/Start';
import MitekSelect from 'containers/Dashboard/Funds/KycVerification/Select';
import MitekStepFront from 'containers/Dashboard/Funds/KycVerification/Step1_1';
import MitekStepBack from 'containers/Dashboard/Funds/KycVerification/Step1_2';
import MitekStepSelfie from 'containers/Dashboard/Funds/KycVerification/Step2';
import MitekReview from 'containers/Dashboard/Funds/KycVerification/Review';
import MitekStatus from 'containers/Dashboard/Funds/KycVerification/Status';

// Transactions
import Transaction from 'containers/Dashboard/Funds/Transaction';
import FiatAccounts from 'containers/Dashboard/Funds/Fiat';
import FiatAccountsDebits from 'containers/Dashboard/Funds/Fiat/Debits';
import FiatAccountsWires from 'containers/Dashboard/Funds/Fiat/Wires';

// KYC
import KycProfile from 'containers/Dashboard/KYC/Profile';
import KycBirthday from 'containers/Dashboard/KYC/KycBirthday';
import KycCountry from 'containers/Dashboard/KYC/KycCountry';
import KycAddress from 'containers/Dashboard/KYC/KycAddress';
import KycDocumentationInfo from 'containers/Dashboard/KYC/Documentation';
import KycDocumentationCountry from 'containers/Dashboard/KYC/DocumentationCountry';
import KycDocumentationState from 'containers/Dashboard/KYC/DocumentationState';
import KycDocumentationTax from 'containers/Dashboard/KYC/DocumentationTax';
import KycTos from 'containers/Dashboard/KYC/Tos';
import KycStatus from 'containers/Dashboard/KYC/Status';
import KycView from 'containers/Dashboard/KYC/View';
import KycPayment from 'containers/Dashboard/KYC/Payment';
import KycAccount from 'containers/Dashboard/KYC/Account';
import SelectKycUserCountry from 'containers/Dashboard/KYC/KycUserCountry';
import SelectKycUserState from 'containers/Dashboard/KYC/KycUserState';
import SelectKycDocCountry from 'containers/Dashboard/KYC/KycDocCountry';
import SelectKycDocState from 'containers/Dashboard/KYC/KycDocState';

// Result Bets
import ResultList from 'containers/Dashboard/Bets/Results';
import ResultFilter from 'containers/Dashboard/Bets/Results/Filter';
import ResultLeagues from 'containers/Dashboard/Bets/Results/Leagues';
import ResultEsports from 'containers/Dashboard/Bets/Results/Esports';
import ResultMine from 'containers/Dashboard/Bets/Results/MyBets';

// Tournaments
import PlayFilter from 'containers/Dashboard/More/Play/Filter';
import Play from 'containers/Dashboard/More/Play';
import ViewTournament from 'containers/Dashboard/More/Play/Tournament/View';
import EnterTournamentPrize from 'containers/Dashboard/More/Play/Tournament/View/PrizeMoney';
import AllocatePrizeMoney from 'containers/Dashboard/More/Play/Tournament/View/AllocatePrizeMoney';
import EditTournament from 'containers/Dashboard/More/Play/Tournament/Edit';
import SelectSport from 'components/Play/SelectSport';
import WatchStreams from 'containers/Dashboard/More/Play/Tournament/View/WatchStreams';
import RegisterForTournament from 'containers/Dashboard/More/Play/Tournament/View/RegisterForTournament';
import LinkTwitch from 'containers/Dashboard/More/Play/Tournament/View/LinkTwitch';
import RegisteredUsers from 'containers/Dashboard/More/Play/Tournament/View/RegisteredUsers';
import UpcomingMatches from 'containers/Dashboard/More/Play/Tournament/View/UpcomingMatches';
import CompletedMatches from 'containers/Dashboard/More/Play/Tournament/View/CompletedMatches';
import MatchDetails from 'containers/Dashboard/More/Play/Tournament/View/MatchDetails';
import Bracket from 'containers/Dashboard/More/Play/Tournament/View/Bracket';
import TournamentInformation from 'containers/Dashboard/More/Play/Tournament/Edit/Step1';
import TournamentFormat from 'containers/Dashboard/More/Play/Tournament/Edit/Step2';
import TournamentDate from 'containers/Dashboard/More/Play/Tournament/Edit/Step3';
import TournamentFees from 'containers/Dashboard/More/Play/Tournament/Edit/Step4';
import AdditionalInfo from 'containers/Dashboard/More/Play/Tournament/Edit/Step5';

const AppRoutes = () => {
  const device = getDeviceType();
  if (device === 'desktop') {
    return (
      <MobileOnlyDialog />
    );
  }
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path="/" component={LandingPage} />
        <PublicRoute exact path="/home" component={LandingPage} />
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute exact path="/register" component={SignUpPage} />
        <PrivateRoute exact path="/welcome" component={WelcomePage} isWelcome />
        <PrivateRoute exact path="/country" component={CountryPage} isWelcome />
        <PrivateRoute exact path="/state" component={StatePage} isWelcome />
        <PrivateRoute exact path="/birthday" component={BirthdayPage} isWelcome />
        <PrivateRoute exact path="/location" component={LocationPage} isWelcome />
        <PrivateRoute exact path="/referral-bonus" component={ReferralBonusPage} isWelcome />
        <PrivateRoute exact path="/casino" component={CasinoPage} isWelcome />

        <PrivateRoute exact path="/bets" component={Bets} />
        <PrivateRoute exact path="/bets/esports" component={Esports} />
        <PrivateRoute exact path="/bets/time-period" component={TimePeriod} />
        <PrivateRoute exact path="/bets/upcoming-games" component={UpcomingGames} />
        <PrivateRoute exact path="/bets/all" component={AllBets} isRates />
        <PrivateRoute exact path="/bets/filter" component={BetsFilter} />
        <PrivateRoute exact path="/bets/view/:betId" component={SingleBet} isRates />

        <PrivateRoute exact path="/bets/create/select-league" component={SelectLeague} />
        <PrivateRoute exact path="/bets/create/select-esport" component={SelectEsport} />
        <PrivateRoute exact path="/bets/create/select-time-period" component={SelectTimePeriod} />
        <PrivateRoute exact path="/bets/create/select-season" component={SelectSeason} />
        <PrivateRoute exact path="/bets/create/select-game" component={SelectGame} />
        <PrivateRoute exact path="/bets/create/select-team-player" component={SelectTeamPlayer} />
        <PrivateRoute exact path="/bets/create/select-bet-type" component={SelectBetType} />
        <PrivateRoute exact path="/bets/create/select-bet-time" component={SelectBetTime} />
        <PrivateRoute exact path="/bets/create/final-submit" component={FinalSubmit} isRates />

        <PrivateRoute exact path="/bets/edit/:betId/select-game" component={EditSelectGame} />
        <PrivateRoute exact path="/bets/edit/:betId/select-team-player" component={EditSelectTeamPlayer} />
        <PrivateRoute exact path="/bets/edit/:betId/select-bet-type" component={EditSelectBetType} />
        <PrivateRoute exact path="/bets/edit/:betId/select-bet-time" component={EditSelectBetTime} />
        <PrivateRoute exact path="/bets/edit/:betId/final-submit" component={EditFinalSubmit} isRates />

        <PrivateRoute exact path="/bets/favorites" component={Favorites} isRates />
        <PrivateRoute exact path="/bets/favorites/filter" component={FavoritesFilter} />
        <PrivateRoute exact path="/bets/favorites/edit" component={EditFavorites} />

        <PrivateRoute exact path="/bets/results" component={ResultList} isRates />
        <PrivateRoute exact path="/bets/results/filter" component={ResultFilter} />
        <PrivateRoute exact path="/bets/results/leagues" component={ResultLeagues} />
        <PrivateRoute exact path="/bets/results/esports" component={ResultEsports} />
        <PrivateRoute exact path="/bets/results/mine" component={ResultMine} isRates />
        <PrivateRoute exact path="/bets/parlay-checkout" component={ParlayCheckout} isRates />
        <PrivateRoute exact path="/bets/results/parlay/:parlayId" component={ParlaySingle} isRates />
        <PrivateRoute exact path="/bets/results/parlay-bet/:parlayId/:betId" component={SingleBet} isRates />
        <PrivateRoute exact path="/bets/results/parlay-bet-submit/:betId" component={SingleBet} isRates />

        <PrivateRoute exact path="/more" component={MoreMenu} />
        <PrivateRoute exact path="/more/setting" component={Setting} />
        <PrivateRoute exact path="/more/setting/display" component={SettingDisplay} />
        <PrivateRoute exact path="/more/setting/profile" component={SettingProfile} />
        <PrivateRoute exact path="/more/setting/profile/country" component={SelectProfileCountry} />
        <PrivateRoute exact path="/more/setting/profile/state" component={SelectProfileState} />
        <PrivateRoute exact path="/more/setting/notification" component={SettingNotification} />
        <PrivateRoute exact path="/more/setting/system-requirements" component={SystemRequirements} />

        <PrivateRoute exact path="/more/referral-bonus" component={ReferralBonus} />
        <PrivateRoute exact path="/more/referral-bonus/view" component={ViewReferralBonus} />

        <PrivateRoute exact path="/more/play" component={Play} />
        <PrivateRoute exact path="/more/play/filter" component={PlayFilter} />

        <PrivateRoute exact path="/more/play/tournament/view/:id" component={ViewTournament} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/prize" component={EnterTournamentPrize} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/allocate-prize-money" component={AllocatePrizeMoney} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/edit" component={EditTournament} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/register-for-tournament" component={RegisterForTournament} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/link-twitch" component={LinkTwitch} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/registered-users" component={RegisteredUsers} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/upcoming-matches" component={UpcomingMatches} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/completed-matches" component={CompletedMatches} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/match-details/:matchId" component={MatchDetails} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/brackets" component={Bracket} />
        <PrivateRoute exact path="/more/play/tournament/view/:id/watch-streams" component={WatchStreams} />

        <PrivateRoute exact path="/more/play/tournament/edit/:id/information" component={TournamentInformation} />
        <PrivateRoute exact path="/more/play/tournament/edit/:id/information/select-sport" component={SelectSport} />
        <PrivateRoute exact path="/more/play/tournament/edit/:id/format" component={TournamentFormat} />
        <PrivateRoute exact path="/more/play/tournament/edit/:id/date-time" component={TournamentDate} />
        <PrivateRoute exact path="/more/play/tournament/edit/:id/fees" component={TournamentFees} isRates />
        <PrivateRoute exact path="/more/play/tournament/edit/:id/additional-info" component={AdditionalInfo} />

        <PrivateRoute exact path="/more/play/tournament/create/information/select-sport" component={SelectSport} />
        <PrivateRoute exact path="/more/play/tournament/create/information" component={TournamentInformation} />
        <PrivateRoute exact path="/more/play/tournament/create/format" component={TournamentFormat} />
        <PrivateRoute exact path="/more/play/tournament/create/date-time" component={TournamentDate} />
        <PrivateRoute exact path="/more/play/tournament/create/fees" component={TournamentFees} isRates />
        <PrivateRoute exact path="/more/play/tournament/create/additional-info" component={AdditionalInfo} />

        <PrivateRoute exact path="/funds" component={FundsMenu} isRates />
        <PrivateRoute exact path="/funds/wallet" component={Wallet} />

        <PrivateRoute exact path="/funds/deposit" component={Deposit} isRates />
        <PrivateRoute exact path="/funds/deposit/wire-transfer" component={DepositWireTransfer} isRestricted />
        <PrivateRoute exact path="/funds/deposit/debit-card/add" component={DepositAddDebitCard} isRestricted />
        <PrivateRoute exact path="/funds/deposit/debit-card/submit" component={DepositSubmitDebitCard} isRestricted />
        <PrivateRoute exact path="/funds/deposit/debit-card/payment" component={DepositPaymentCard} isRestricted />
        <PrivateRoute exact path="/funds/deposit/:currency/:transactionId" component={DepositInstruction} isRestricted />
        <PrivateRoute exact path="/funds/deposit/:currency/:transactionId/confirmation" component={DepositConfirmation} isRestricted />

        <PrivateRoute exact path="/funds/exchange/" component={Markets} />
        <PrivateRoute exact path="/funds/exchange/:id" component={Market} />
        <PrivateRoute exact path="/funds/rewards/" component={Rewards} />

        <PrivateRoute exact path="/funds/withdraw/wire-transfer/instructions" component={WithdrawWireInstructions} isRestricted />
        <PrivateRoute exact path="/funds/withdraw/wire-transfer/instructions/bank-country" component={SelectBankCountry} isRestricted />
        <PrivateRoute exact path="/funds/withdraw/wire-transfer/instructions/country" component={SelectWithdrawCountry} isRestricted />
        <PrivateRoute exact path="/funds/withdraw/wire-transfer/instructions/state" component={SelectWithdrawState} isRestricted />
        <PrivateRoute exact path="/funds/withdraw/wire-transfer/submit" component={WithdrawWireSubmit} isRestricted />
        <PrivateRoute exact path="/funds/withdraw/:currency" component={Withdraw} isRates />

        <PrivateRoute exact path="/mitek_start" component={MitekStart} />
        <PrivateRoute exact path="/mitek_select" component={MitekSelect} />
        <PrivateRoute exact path="/mitek_step1_1" component={MitekStepFront} />
        <PrivateRoute exact path="/mitek_step1_2" component={MitekStepBack} />
        <PrivateRoute exact path="/mitek_step2" component={MitekStepSelfie} />
        <PrivateRoute exact path="/mitek_review" component={MitekReview} />
        <PrivateRoute exact path="/mitek_status" component={MitekStatus} />

        <PrivateRoute exact path="/funds/transaction/:currency" component={Transaction} isRates />

        <PrivateRoute exact path="/funds/fiat-accounts" component={FiatAccounts} />
        <PrivateRoute exact path="/funds/fiat-accounts/debit-cards" component={FiatAccountsDebits} />
        <PrivateRoute exact path="/funds/fiat-accounts/wires" component={FiatAccountsWires} />

        <PrivateRoute exact path="/kyc/profile" component={KycProfile} isRestricted />
        <PrivateRoute exact path="/kyc/profile/birthday" component={KycBirthday} isRestricted />
        <PrivateRoute exact path="/kyc/profile/country" component={KycCountry} isRestricted />
        <PrivateRoute exact path="/kyc/profile/select-country" component={SelectKycUserCountry} isRestricted />
        <PrivateRoute exact path="/kyc/profile/address" component={KycAddress} isRestricted />
        <PrivateRoute exact path="/kyc/profile/select-state" component={SelectKycUserState} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/country" component={KycDocumentationCountry} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/state" component={KycDocumentationState} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/select-country" component={SelectKycDocCountry} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/select-state" component={SelectKycDocState} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/tax" component={KycDocumentationTax} isRestricted />
        <PrivateRoute exact path="/kyc/documentation/info" component={KycDocumentationInfo} isRestricted />
        <PrivateRoute exact path="/kyc/tos" component={KycTos} isRestricted />
        <PrivateRoute exact path="/kyc/status" component={KycStatus} isRestricted />
        <PrivateRoute exact path="/kyc/view" component={KycView} isRestricted />
        <PrivateRoute exact path="/kyc/resolve" component={KycView} isRestricted />
        <PrivateRoute exact path="/kyc/payment" component={KycPayment} isRates isRestricted />
        <PrivateRoute exact path="/kyc/account" component={KycAccount} isRestricted />

        <PrivateRoute exact path="/notifications" component={NotifsMenu} />

        <Redirect to="/bets" />
      </Switch>
      <SuccessModal />
      <ConfirmModal />
      <ErrorModal />
    </Router>
  );
};

export default AppRoutes;
