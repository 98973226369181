import axios from 'axios';

import config from 'utils/config';

const http = axios.create({});

function getAuthHeader() {
  const accessToken = localStorage.getItem(config.userTokenKey);
  let authHeader = { 'Content-Type': 'application/json' };
  if (accessToken) {
    authHeader = {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };
  }
  return authHeader;
}

function get(url, headers = {}, params = {}, baseURL = config.baseUrl) {
  return http.get(url, {
    baseURL,
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function post(url, data, headers = {}, params = {}, baseURL = config.baseUrl) {
  return http.post(url, data, {
    baseURL,
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function patch(url, data, headers = {}, params = {}, baseURL = config.baseUrl) {
  return http.patch(url, data, {
    baseURL,
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function put(url, data, headers = {}, params = {}, baseURL = config.baseUrl) {
  return http.put(url, data, {
    baseURL,
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

function remove(url, data, headers = {}, params = {}, baseURL = config.baseUrl) {
  return http.delete(url, {
    baseURL,
    params,
    headers: { ...getAuthHeader(), ...headers },
  });
}

export default {
  http,
  get,
  post,
  patch,
  put,
  remove,
};
