import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isStandalone } from 'utils';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import ZenDialog from 'components/Dialog/ZenDialog';
import 'containers/Dashboard/More/more.css';

import rewardImage from 'assets/images/setting/sports_token.png';
import referralImage from 'assets/images/setting/referral.png';
import settingImage from 'assets/images/setting/setting.png';
import helpImage from 'assets/images/setting/help.png';
// import playImage from 'assets/images/setting/play.png';
// import messagesImage from 'assets/images/setting/messages.png';
// import socialImage from 'assets/images/setting/social.png';
// import youImage from 'assets/images/setting/you.png';

import { useUserRestrictedFeatures } from 'hooks/redux/user';
import { comingSoonMessage } from 'utils/constants';

const navItems = [
  {
    path: '/more/referral-bonus',
    label: 'Referral Bonuses',
    icon: referralImage,
    restrictedTerms: [],
  },
  {
    path: '/funds/rewards',
    label: 'ZenSports Rewards',
    icon: rewardImage,
    restrictedTerms: [],
  },
  // {
  //   path: '/more/coming-soon2',
  //   label: 'Social',
  //   icon: socialImage,
  // },
  // {
  //   path: '/more/play',
  //   label: 'Play',
  //   icon: playImage,
  //   restrictedTerms: ['rec_sports_visibility'],
  // },
  // {
  //   path: '/more/coming-soon4',
  //   label: 'You',
  //   icon: youImage,
  // },
  // {
  //   path: '/more/coming-soon5',
  //   label: 'Messages',
  //   icon: messagesImage,
  // },
  {
    path: '/more/setting',
    label: 'Settings',
    icon: settingImage,
    restrictedTerms: [],
  },
  {
    path: '/more/help',
    label: 'Help',
    icon: helpImage,
    restrictedTerms: [],
  },
];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  navigation: {
    background: palette.cardColor,
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    margin: '15px 0',
    padding: '10px 24px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
    position: 'relative',
    overflow: 'hidden',
  },
  icon: {
    width: '45px',
    height: '45px',
    display: 'block',
  },
}));

const More = ({ history }) => {
  const classes = useStyles();
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);

  const restrictedFeatures = useUserRestrictedFeatures();

  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };

  const handleNext = useCallback((e, path) => {
    e.target.classList.add('ripple');
    setTimeout(() => {
      e.target.classList.remove('ripple');
      if (path === '/more/help') {
        if (isStandalone) {
          window.open('https://support.zensports.com/', '_blank');
        } else {
          window.location.href = 'https://support.zensports.com/';
        }
      } else if (path.includes('coming-soon')) {
        setComingSoonDialogOpen(true);
      } else {
        history.push(path);
      }
    }, 100);
  }, [history]);

  const isRestricted = (terms) => {
    if (terms.length && restrictedFeatures.length) {
      return terms.some((item) => restrictedFeatures.indexOf(item) >= 0);
    }
    return false;
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          {navItems.map((navItem) => (
            !isRestricted(navItem.restrictedTerms) && (
              <Box
                onClick={(e) => handleNext(e, navItem.path)}
                className={classes.navigation}
                key={navItem.path}
                display="flex"
                alignItems="center"
              >
                <Box mr={3}>
                  <img src={navItem.icon} alt="navigation" className={classes.icon} />
                </Box>
                {navItem.label}
              </Box>
            )
          ))}
        </Box>
      </Box>
      {comingSoonDialogOpen && (
        <ZenDialog
          title="Feature Coming Soon!"
          message={comingSoonMessage}
          cta="Close"
          customCta="Download iOS/Android App"
          customCtaLink="zensports.com"
          onClose={handleCloseComingSoonDialog}
        />
      )}
    </Page>
  );
};

More.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps)(More));
