import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StarRateIcon from '@material-ui/icons/StarRate';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import TwitchPlayer from 'components/Play/Tournament/View/TwitchPlayer';

import { formattedDate } from 'utils';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    top: -15,
    marginBottom: '10px',
  },
  contentContainer: {
    padding: '0px 20px 10px',
  },
  typesText: {
    color: '#b5b5b5',
    marginTop: 5,
  },
  title: {
    font: '30px SofiaPro-Bold',
    fontSize: '21px',
    letterSpacing: -0.3,
    color: theme.palette.secondaryColor,
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '13px 0 10px',
  },
  tag: {
    background: '#6236ff',
    color: '#fff',
    padding: '3px 12px',
    fontSize: '11px',
    borderRadius: '20px',
    fontWeight: 'lighter',
  },
  dateText: {
    color: '#52d4e3',
    fontSize: '12px',
  },
  line: {
    background: '#d3d3db',
    height: '1px',
  },
  contentText: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondaryColor,
    margin: '10px 20px 10px',
    fontSize: '13px',
  },
  videoIcon: {
    paddingRight: 8,
  },
}));

const Summary = ({ details, history }) => {
  const classes = useStyles();

  const getDescription = () => {
    let description = '';
    if (details) {
      if (details.location) {
        description += `${details.location} `;
      }
      if (details.format_display) {
        description += `- ${details.format_display} `;
      }
      if (details.weight_display) {
        description += `- ${details.weight_display} `;
      }
    }
    return description;
  };

  return (
    <Box className={`cardOverride ${classes.card}`}>
      <Box className={classes.contentContainer}>
        <Box className={classes.tagContainer}>
          <Box className={classes.tag}>
            {details.game_type_display}
          </Box>
          <Typography className={classes.dateText}>
            {formattedDate(details.date_iso8601)}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          {details.name}
        </Typography>
        <Typography className={classes.typesText}>
          {getDescription()}
        </Typography>
      </Box>
      <TwitchPlayer channel={details.twitch_channel_url} />
      <Box>
        <Typography
          className={classes.contentText}
          onClick={() => history.push(`/more/play/tournament/view/${details.id}/watch-streams`)}
        >
          <PlayCircleOutlineIcon className={classes.videoIcon} />
          Watch More Streams
        </Typography>
        <Box className={classes.line} />
        <Typography
          className={classes.contentText}
          onClick={() => history.push(`/more/play/tournament/view/${details.id}/register-for-tournament`)}
        >
          <StarRateIcon className={classes.videoIcon} />
          Register For Tournament
        </Typography>
      </Box>
    </Box>
  );
};

Summary.propTypes = {
  details: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Summary);
