import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import BackButton from 'components/Buttons/BackButton';
import BetInfoCard from 'components/Bet/BetInfoCard';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import ParlayCart from 'components/Parlay/ParlayCart';

import { getBetInfo } from 'utils/betting';
import { getFilterQuery } from 'utils/betting.sort';
import { isParlayAvailable } from 'utils/parlay';
import { useBet, useBetList, useBetSelected } from 'hooks/redux/bet';
import { useMappedCurrencies } from 'hooks/redux/currency';
import {
  useUserOddType, useAuthUser, useUserRestrictedAccess, useUpdateUserLocation,
} from 'hooks/redux/user';
import { useParlay, useAddParlayDispatch } from 'hooks/redux/parlay';
import storageService from 'services/storage.service';
import { useOpenConfirmModalDispatch } from 'hooks/redux/modal';
import 'containers/Dashboard/More/more.css';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  emptyState: {
    textAlign: 'center',
    color: '#aeaeae',
    marginTop: '120px',
    lineHeight: '1.2',
  },
  pillMenu: {
    display: 'block',
    listStyle: 'none',
    background: palette.pillMenuBgColor,
    width: '266px',
    overflow: 'auto',
    borderRadius: '24px',
    margin: '0 auto 25px auto',
    padding: '2px;',
    '& li a': {
      float: 'left',
      color: palette.pillInactiveColor,
      padding: '15px 20px',
      borderRadius: '25px',
    },
    '& li a.active': {
      color: palette.secondaryColor,
      background: palette.pillActiveBgColor,
    },
  },
}));

const ColorButton = withStyles(({ palette }) => ({
  root: {
    background: palette.colorBtn,
    boxShadow: 'none',
    minHeight: 40,
    font: '16px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: 'white',
    padding: '0 30px',
    '&:hover': {
      background: palette.colorBtnTxt,
    },
  },
}))(Button);

const AllBets = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');

  const [openTab, setOpenTab] = useState('tab_zensports');
  const openConfirmModal = useOpenConfirmModalDispatch();

  const betting = useBet();
  const bets = useBetList(debounceSearch, options);
  const filter = useBetSelected();
  const oddType = useUserOddType();
  const user = useAuthUser();
  const mappedCurrencies = useMappedCurrencies();
  const parlay = useParlay();
  const addParlay = useAddParlayDispatch();
  const access = useUserRestrictedAccess();
  const updateUserLocation = useUpdateUserLocation();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleFilter = () => {
    history.push('/bets/filter');
  };

  const handleSelectCard = useCallback((e, betId) => {
    const rc = e.target.closest('.rippleContainer');
    rc.classList.add('ripple');
    setTimeout(() => {
      rc.classList.remove('ripple');
      history.push(`/bets/view/${betId}`);
    }, 100);
    updateUserLocation(user);
  }, [history, updateUserLocation, user]);

  const handleAddParlay = useCallback((bet) => {
    addParlay(bet);
    updateUserLocation(user);
  }, [updateUserLocation, addParlay, user]);

  const handleCreateBet = () => {
    history.push('/bets/create/select-league');
  };

  const handleTabClick = (e) => {
    setOpenTab(e.target.id);

    if (e.target.id === 'tab_p2p') {
      let rememberPopups = storageService.getItem('remember_popups');
      if (!rememberPopups) {
        rememberPopups = {};
      }
      if (!rememberPopups.p2p_bets) {
        openConfirmModal({
          title: 'Peer-to-Peer Bets',
          subtitle: `You are about to venture into the world
          of Peer-to-Peer bets. These are bets that have been
          created by other sports bettors like yourself. Peer-to-Peer bets
          have an additional fee attached to them, equal to 10% of the
          amount of your wager. This fee is to cover ZenSports' costs
          in running this marketplace, since these bets do NOT come
          from ZenSports.`,
          agreeBtnText: 'Got It',
          disagreeBtnText: 'Got It & Don\'t Show Again',
          callback: () => {},
          cancelCallback: async () => {
            rememberPopups.p2p_bets = true;
            storageService.setItem('remember_popups', rememberPopups);
          },
        });
      }
    }
  };

  useEffect(() => {
    if (filter && user.id && mappedCurrencies) {
      filter.user = user;
      filter.isCryptoVisible = access.isCryptoVisible;
      filter.makerIsZensports = openTab === 'tab_zensports';
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter, mappedCurrencies),
        filter,
      });
    }
  // eslint-disable-next-line
  }, [filter, access.isCryptoVisible, openTab]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL,hocky, Lakers…"
            value={search}
          />
          <TuneIcon className={classes.filterBtn} onClick={() => handleFilter()} />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ul className={classes.pillMenu}>
            <li>
              <a id="tab_zensports" className={(openTab === 'tab_zensports') ? 'active' : ''} onClick={handleTabClick}>
                ZenSports Bets
              </a>
            </li>
            <li>
              <a id="tab_p2p" className={(openTab === 'tab_p2p') ? 'active' : ''} onClick={handleTabClick}>
                P2P Bets
              </a>
            </li>
          </ul>
        </Box>

        <Box className={commonClasses.vListWrapper}>
          {!betting.isLoading ? (bets.length > 0 ? (
            <VList
              rowCount={bets.length}
              rowRender={(index) => (
                <>
                  {index === 0
                  && (
                  <Box display="flex" justifyContent="center" alignItems="center" mb={3} mt={3}>
                    <Typography variant="h3" className={classes.title}>
                      Bets
                    </Typography>
                  </Box>
                  )}
                  {bets[index].type !== 'parlay' && (
                    <BetInfoCard
                      betInfo={getBetInfo(bets[index], oddType)}
                      showAcceptBet={!filter.isExpired}
                      showAddParlay={!filter.isExpired && isParlayAvailable(bets[index], parlay)}
                      onAddParlay={() => handleAddParlay(bets[index])}
                      onClick={(e) => handleSelectCard(e, bets[index].id)}
                    />
                  )}
                </>
              )}
            />
          ) : (
            <>
              <Typography className={classes.emptyState} variant="subtitle2">
                It doesn’t look like there are any bets available right now that fit the criteria you selected.
                But good news - ZenSports lets you create YOUR OWN bets for anything you want. Tap the “Create Bet”
                button below to get started!
              </Typography>
              <Box textAlign="center" mt={4}>
                <ColorButton onClick={handleCreateBet}>
                  Create Bet
                </ColorButton>
              </Box>
            </>
          )) : (<Loading />)}
        </Box>
      </Box>
      {parlay?.list.length > 0 && (
        <ParlayCart parlay={parlay} />
      )}
    </Page>
  );
};

AllBets.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AllBets);
