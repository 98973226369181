import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import {
  usePlayIsLoading,
  useGetTournamentDetail,
  useGetPrizeMoneyDirector,
} from 'hooks/redux/play';
import { formatCurrency } from 'utils/currency';
import { supportedAllMappedCurrencies } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 15,
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  balanceContainer: {
    padding: '0px 20px 10px',
    alignItems: 'center',
  },
  balanceAmount: ({ color }) => ({
    fontWeight: 'bold',
    fontSize: '30px',
    color,
  }),
  line: {
    background: '#d3d3db',
    height: '2px',
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    marginBottom: '10px',
    padding: '10px 0 1px',
  },
  contentText: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondaryColor,
    margin: '13px 0',
    fontSize: '13px',
  },
  editIcon: {
    paddingRight: 14,
    width: 20,
    height: 20,
  },
  detailsContainer: {
    display: 'flex',
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldLabel: {
    fontSize: '17px',
    color: theme.palette.secondaryColor,
  },
  feeContent: ({ color }) => ({
    color,
    textAlign: 'end',
    fontSize: '18px',
  }),
  dLine: {
    background: '#b5b3b3',
    height: '2px',
    margin: '18px 0 15px',
  },
}));

const EnterPrize = ({ match, history }) => {
  const { id } = match.params;
  const isLoading = usePlayIsLoading();
  const details = useGetTournamentDetail(id);
  const prizeMoneyDirector = useGetPrizeMoneyDirector(id);

  const classes = useStyles({
    color: supportedAllMappedCurrencies[details?.currency?.symbol]?.color || 'black',
  });

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <Box>
            <BackButton color={classes.backBtn} />
          </Box>
          <Box textAlign="center" mb={4} mt={2}>
            <Typography component="h3" className={classes.title}>
              Enter Fees/Prize Money
            </Typography>
            <Typography component="h3" className={classes.subtitle}>
              Manage your fees and allocate your prize money
            </Typography>
          </Box>
        </Box>
        {(!isLoading && details) ? (
          <>
            <Box className={`cardOverride ${classes.card}`}>
              <Box
                className={classes.balanceContainer}
                display="flex"
                alignItems="center"
              >
                <Grid item xs={4} sm={4}>
                  <Box>
                    <img src={details.currency?.wallet_image_url} alt="Currency" />
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Box>
                    <Typography className={classes.cardTitle}>
                      Total Prize Money
                    </Typography>
                    <Typography className={classes.balanceAmount}>
                      {details.currency && prizeMoneyDirector?.total_prize_money >= 0 && (
                        formatCurrency(
                          prizeMoneyDirector.total_prize_money,
                          details.currency,
                          false,
                        )
                      )}
                    </Typography>
                    <Typography className={classes.cardTitle}>
                      {details.currency?.symbol?.toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
              </Box>
              <Box className={classes.line} />
              <Box>
                <Typography
                  className={classes.contentText}
                  onClick={() => history.push(`/more/play/tournament/view/${id}/allocate-prize-money`)}
                >
                  <BorderColorIcon className={classes.editIcon} />
                  Allocate Prize Money
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box className={classes.detailsContainer}>
                <Typography className={classes.fieldLabel}>
                  Entry Fee
                </Typography>
                <Typography className={classes.feeContent}>
                  {details.fee_display}
                </Typography>
              </Box>
              <Box className={classes.dLine} />
            </Box>
            <Box>
              <Box className={classes.detailsContainer}>
                <Typography className={classes.fieldLabel}>
                  Number of Entries
                </Typography>
                <Typography
                  className={classes.feeContent}
                  style={{ color: supportedAllMappedCurrencies[details.currency?.symbol]?.color }}
                >
                  {prizeMoneyDirector?.entries_regular_count}
                </Typography>
              </Box>
              <Box className={classes.dLine} />
            </Box>
            <Box>
              <Box className={classes.detailsContainer}>
                <Typography className={classes.fieldLabel}>
                  Prize Money from Entries
                </Typography>
                <Typography
                  className={classes.feeContent}
                  style={{ color: supportedAllMappedCurrencies[details.currency?.symbol]?.color }}
                >
                  {details.fee > 0 && prizeMoneyDirector && (
                    formatCurrency(
                      details.fee * prizeMoneyDirector.entries_regular_count,
                      details.currency,
                      true,
                    )
                  )}
                </Typography>
              </Box>
              <Box className={classes.dLine} />
            </Box>
          </>
        ) : null}
      </Box>
    </Page>
  );
};

EnterPrize.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(EnterPrize);
