import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import commonStyles from 'styles/common';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import InputBox from 'components/Form/InputBox';

import { useAuthUser } from 'hooks/redux/user';
import { usePlayIsLoading, useGetTournamentDetail, useUpdateTwitchDispatch } from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  form: {
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  sportLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    color: theme.palette.secondaryColor,
    fontSize: '15px',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 15,
  },
  info: {
    boxShadow: '2px 2px 9px rgb(0 0 0 / 20%)',
    background: theme.palette.cardColor,
    border: 'none',
    borderRadius: 5,
    '& input': {
      fontSize: '18px',
    },
    '& div': {
      border: 'none',
      borderRadius: 10,
    },
  },
}));

const LinkTwitch = ({ match, history }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const { id } = match.params;
  const isLoading = usePlayIsLoading();
  const details = useGetTournamentDetail(id);
  const updateTwitch = useUpdateTwitchDispatch();
  const user = useAuthUser();

  const openErrorModal = useOpenErrorModalDispatch();

  return (
    <Page>
      {(!isLoading && details && user) && (
        <Formik
          initialValues={{
            twitch_channel_url: details.users?.find(
              (_user) => _user.id === user.id,
            )?.twitch_channel_url || undefined,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              // eslint-disable-next-line no-useless-escape
              if (!new RegExp(/^[a-zA-Z0-9\-]+$/).test(values.twitch_channel_url)) {
                openErrorModal({
                  title: 'Invalid Twitch Username',
                  subtitle: 'Twitch usernames must be alphanumeric values only.',
                });
                return;
              }
              setSubmitting(true);
              const query = {
                twitch_channel_url: values.twitch_channel_url,
              };
              await updateTwitch(id, query);
              history.push(`/more/play/tournament/view/${id}`);
            } catch (e) {
              const message = getErrorMessage(e);
              openErrorModal({ title: 'Oops', subtitle: message });
            }
          }}
          validationSchema={Yup.object().shape({
            twitch_channel_url: Yup
              .string()
              .optional(),
          })}
        >
          {({
            errors,
            touched,
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
              <Box className={classes.root}>
                <Box>
                  <Box>
                    <BackButton color={classes.backBtn} />
                  </Box>
                  <Box textAlign="center" mb={4} mt={2}>
                    <Typography component="h3" className={classes.title}>
                      Link Twitch Channel
                    </Typography>
                    <Typography component="h3" className={classes.subtitle}>
                      Link your Twitch channel so that others can watch your stream for this tournament.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.sportLabel}>Twitch Channel</Typography>
                    <Box className={classes.info} mt={1}>
                      <InputBox
                        label="Enter Twitch Username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.twitch_channel_url}
                        name="twitch_channel_url"
                        format="outline"
                        isWritable
                      />
                    </Box>
                    {
                      errors.twitch_channel_url && touched.twitch_channel_url
                        ? (
                          <Typography className={commonClasses.error}>
                            {errors.twitch_channel_url}
                          </Typography>
                        ) : null
                    }
                  </Box>
                </Box>
                <OutlineButton
                  type="submit"
                  style={{ margin: '65px 0 20px' }}
                  disabled={isSubmitting}
                >
                  Save
                </OutlineButton>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Page>
  );
};

LinkTwitch.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(LinkTwitch);
