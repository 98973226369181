import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Page from 'components/Page/Dashboard';
import Tabs from 'components/Tabs/Tabs';
import BackButton from 'components/Buttons/BackButton';
import ShareButton from 'components/Buttons/ShareButton';
import Loading from 'components/Loading/Box';

import Summary from 'components/Play/Tournament/View/Summary';
import Details from 'components/Play/Tournament/View/Details';
import Matches from 'components/Play/Tournament/View/Matches';
import PrizeMoney from 'components/Play/Tournament/View/PrizeMoney';
import RegisteredUsers from 'components/Play/Tournament/View/RegisteredUsers';

import {
  usePlayIsLoading,
  useGetTournamentDetail,
  useGetTournamentDetailsDispatch,
  useUpcomingMatches,
  useCompletedMatches,
  useGetPrizeMoneyPlayer,
  useDeleteTournamentDispatch,
  useCloseTournamentDispatch,
} from 'hooks/redux/play';
import { useAuthUser } from 'hooks/redux/user';
import { useOpenConfirmModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage, shareLinks } from 'utils';

import CoinsImage from 'assets/images/play/coins.png';
import CopyImage from 'assets/images/play/copy.png';
import EditImage from 'assets/images/play/edit.png';
import TrashImage from 'assets/images/play/trash.png';
import TwitchImage from 'assets/images/play/twitch-rect.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  menu: {
    background: '#0000004d',
    marginLeft: 10,
  },
  bannerContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: '-10px -25px',
    height: '220px',
    padding: '20px',
  },
  tabContainer: {
    '& > div': {
      background: theme.palette.themeColor,
    },
    '& header': {
      width: '100% !important',
      marginLeft: '0px !important',
    },
    '& .MuiTabs-flexContainer .MuiTab-wrapper': {
      color: '#adadbc',
      fontSize: '16px',
      textTransform: 'capitalize',
    },
    '& header .MuiTabs-root': {
      height: 'auto',
    },
    '& .MuiTab-fullWidth': {
      flexGrow: 0,
      maxWidth: 'auto',
      flexBasis: 'auto',
      flexShrink: 'auto',
      padding: '6px 15px 6px 0px',
    },
    '& .MuiTabs-scroller': {
      overflow: 'auto !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#00dde1',
    },
    '& .MuiTabs-root': {
      width: '100%',
      marginLeft: 0,
    },
  },
  topHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dotIcon: {
    color: 'white',
  },
  popover: {
    '& .MuiPopover-paper': {
      background: theme.palette.inactiveBtnColor,
      borderRadius: 10,
      '& li': {
        color: theme.palette.secondaryColor,
      },
    },
  },
  menuImg: {
    width: 'auto',
    height: 19,
    marginRight: 10,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: 'black',
    '&:focus': {
      color: 'black',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MenuItems = [
  {
    type: 'edit',
    text: 'Edit Tournament',
    image: EditImage,
  },
  {
    type: 'allocate',
    text: 'Allocate Prize Money',
    image: CoinsImage,
  },
  {
    type: 'clone',
    text: 'Clone Tournament',
    image: CopyImage,
  },
  {
    type: 'delete',
    text: 'Delete Tournament',
    image: TrashImage,
  },
];

const userMenuItems = [
  {
    type: 'edit_profile',
    text: 'Edit Profile',
    image: EditImage,
  },
  {
    type: 'link_twitch',
    text: 'Link Twitch Channel',
    image: TwitchImage,
  },
];

const ViewTournament = ({ match, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const { id } = match.params;
  const authUser = useAuthUser();
  const loading = usePlayIsLoading();
  const details = useGetTournamentDetail(id);
  const upcomingMatchDetails = useUpcomingMatches(id);
  const completedMatchDetails = useCompletedMatches(id);
  const playerPrizeDetails = useGetPrizeMoneyPlayer(id);
  const deleteTournament = useDeleteTournamentDispatch();
  const closeTournamentEntries = useCloseTournamentDispatch();
  const getTournamentDetails = useGetTournamentDetailsDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (type) => {
    if (type === 'edit') {
      history.push(`/more/play/tournament/view/${id}/edit`);
    } else if (type === 'allocate') {
      history.push(`/more/play/tournament/view/${id}/prize`);
    } else if (type === 'delete') {
      const message = `Are you absolutely sure you want to delete this tournament. 
      This can NOT be un-done once you delete it.`;
      openConfirmModal({
        title: 'Please Confirm!',
        subtitle: message,
        agreeBtnText: 'Yes',
        disagreeBtnText: 'Keep the tournament',
        callback: async () => {
          try {
            await deleteTournament(id);
            history.push('/more/play');
          } catch (e) {
            openErrorModal({
              title: 'Oops',
              subtitle: getErrorMessage(e),
            });
          }
        },
      });
    } else if (type === 'clone') {
      const message = `The Clone Tournament feature isn't quite ready yet. if you'details
      like for us to add this feature so that you can create a similar tournament to this
      one, please chat with us in the Help section below, in the meantime, you can
      definitely still use every other feature on this screen!`;
      openConfirmModal({
        title: 'Not quite ready yet',
        subtitle: message,
        agreeBtnText: 'Got it',
        disagreeBtnText: '',
        callback: () => {},
      });
    } else if (type === 'edit_profile') {
      history.push('/more/setting/profile');
    } else if (type === 'link_twitch') {
      history.push(`/more/play/tournament/view/${id}/link-twitch`);
    }
    handleClose();
  };

  const handleShareLink = () => {
    const shareInfo = {
      title: details.name,
      description: details.notes,
      imageUrl: details.profile_url,
    };
    const linkData = {
      campaign: `${authUser.first_name}${authUser.last_name ? ` ${authUser.last_name}` : ''} `,
      feature: details.format_display,
      tags: [authUser.id],
      data: {
        tournament_id: details.id,
        $og_title: shareInfo.title,
        $og_description: 'Click here to see the details and to register.',
        $og_image_url: shareInfo.imageUrl,
      },
    };
    window.branch.link(linkData, (err, link) => {
      if (!err) {
        shareLinks({
          url: link,
          title: '',
          text: '',
        });
      } else {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    });
  };

  const handleTournamentClose = async () => {
    try {
      await closeTournamentEntries(id);
      getTournamentDetails(id);
    } catch (e) {
      openErrorModal({
        title: 'Oops',
        subtitle: getErrorMessage(e),
      });
    }
  };

  const isDirector = useCallback(() => details.directors?.findIndex(
    (director) => director.id === authUser.id,
  ) >= 0, [details, authUser]);

  const isRegistered = useCallback(() => details.users?.findIndex(
    (user) => user.id === authUser.id,
  ) >= 0, [details, authUser]);

  const renderDetailsTabs = () => [
    {
      label: 'Details',
      component: <Details details={details} />,
    },
    {
      label: 'Prize Money',
      component: <PrizeMoney
        isDirector={isDirector()}
        currency={details?.currency}
        payouts={
            playerPrizeDetails?.payouts?.length > 0
              ? playerPrizeDetails.payouts
              : []
          }
      />,
    },
  ];

  const renderMatchesTabs = () => [
    {
      label: 'Upcoming',
      component: <Matches
        id={Number(id)}
        type="upcoming"
        matches={upcomingMatchDetails}
        page="upcoming-matches"
        closed={!!details?.closed}
        isDirector={isDirector()}
        closeEntries={handleTournamentClose}
      />,
    },
    {
      label: 'Completed',
      component: <Matches
        id={Number(id)}
        type="completed"
        matches={completedMatchDetails}
        closed={!!details?.closed}
        page="completed-matches"
        isDirector={isDirector()}
        closeEntries={handleTournamentClose}
      />,
    },
  ];

  return (
    <Page>
      <Box className={classes.root}>
        {(!loading && details && Object.keys(details).length > 0) ? (
          <>
            <Box
              className={classes.bannerContainer}
              style={{ backgroundImage: `url('${details.profile_url}')` }}
            >
              <Box className={classes.topHeader}>
                <Box>
                  <BackButton background="#0000004d" page="/more/play" />
                </Box>
                <Box display="flex">
                  <ShareButton type="white" background="#0000004d" onClick={handleShareLink} />
                  {
                    ((isDirector() || isRegistered()) && (
                      <Box className={classes.menu}>
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          className={classes.popover}
                          onClose={handleClose}
                        >
                          {(isDirector() ? MenuItems : userMenuItems).map((menu) => (
                            <StyledMenuItem
                              key={menu.type}
                              onClick={() => handleMenuClick(menu.type)}
                            >
                              <img src={menu.image} className={classes.menuImg} alt="Menu" />
                              <Typography className={classes.menuText}>
                                {menu.text}
                              </Typography>
                            </StyledMenuItem>
                          ))}
                        </StyledMenu>
                        <MoreHorizIcon
                          className={classes.dotIcon}
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          onClick={handleClick}
                        />
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </Box>
            <Summary details={details} />
            <RegisteredUsers
              id={Number(id)}
              users={details?.users}
              isDirector={isDirector()}
            />
            <Box className={classes.tabContainer}>
              <Tabs tabs={renderMatchesTabs()} hidePadding />
            </Box>
            <Box className={classes.tabContainer}>
              <Tabs tabs={renderDetailsTabs()} hidePadding />
            </Box>
          </>
        ) : (<Loading />)}
      </Box>
    </Page>
  );
};

ViewTournament.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ViewTournament);
