import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/StarBorder';

import Page from 'components/Page/Dashboard';
import BetListCard from 'components/Bet/BetListCard';
import Loading from 'components/Loading/Box';
import ParlayCart from 'components/Parlay/ParlayCart';

import { getLeagueInfo } from 'utils/betting';
import { useLeagues, useBet, useSetLeagueDispatch } from 'hooks/redux/bet';
import { useUserRestrictedAccess } from 'hooks/redux/user';
import { useParlay } from 'hooks/redux/parlay';

import LogoImage from 'assets/images/dashboard/color_logo.png';
import LogoMarkImage from 'assets/images/dashboard/white_no_text_logo.png';

const useStyles = makeStyles(({ palette }) => ({
  logo: {
    paddingBottom: '10px',
    position: 'relative',
  },
  logoImage: {
    height: '36px',
  },
  starIcon: {
    position: 'absolute',
    right: 0,
    color: palette.secondaryColor,
  },
  logoMark: {
    background: 'linear-gradient(90deg, rgba(41,144,219,1) 0%, rgba(42,207,211,1) 100%)',
    padding: '13px 18px 10px',
    borderRadius: '5px',
    marginBottom: '8px',
    flexDirection: 'column',
    position: 'relative',
  },
  logoMarkImage: {
    position: 'absolute',
    height: '90%',
    left: '6px',
    top: '5%',
    display: 'block',
    opacity: '0.2',
  },
  title: {
    fontFamily: 'SofiaPro-Bold',
    color: 'white',
    fontSize: '26px',
    lineHeight: '20px',
    letterSpacing: -0.46,
  },
  acceptTitle: {
    fontFamily: 'SofiaPro-Bold',
    color: 'black',
    fontSize: '26px',
    lineHeight: '20px',
    letterSpacing: -0.46,
  },
  content: {
    fontFamily: 'SofiaPro-Regular',
    color: 'white',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: -0.3,
    paddingBottom: '10px',
  },
  betButton: {
    // background: palette.betBtn,
    backgroundColor: '#14133D',
    '& .MuiButton-label': {
      color: 'white',
      fontSize: '18px',
      fontFamily: 'SofiaPro-Medium',
      textTransform: 'capitalize',
    },
  },
}));

const Bets = ({ history }) => {
  const classes = useStyles();
  const leagues = useLeagues();
  const betting = useBet();
  const parlay = useParlay();
  const setLeague = useSetLeagueDispatch();
  const access = useUserRestrictedAccess();

  const handleNext = useCallback((type, league) => {
    setLeague(league);
    if (type === 'group') {
      history.push('/bets/esports');
    } else {
      history.push('/bets/time-period');
    }
  }, [setLeague, history]);
  const handleCreateBet = () => {
    history.push('/bets/create/select-league');
  };

  return (
    <Page>
      <Box style={{ paddingBottom: '20px' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.logo}
        >
          {access.isBetOrFantasyVisible ? (
            <img src={LogoImage} alt="logo" className={classes.logoImage} />
          ) : (
            <Typography variant="h1" className={classes.acceptTitle}>
              Accept Bet
            </Typography>
          )}
          <StarIcon className={classes.starIcon} onClick={() => history.push('/bets/favorites')} />
        </Box>
        {access.isBetOrFantasyVisible && (
          <Box
            className={classes.logoMark}
            display="flex"
          >
            <img src={LogoMarkImage} className={classes.logoMarkImage} alt="logo" />
            <Typography variant="h1" className={classes.title}>
              Welcome to ZenSports!
            </Typography>
            <Typography variant="subtitle1" className={classes.content}>
              Win more by creating / accepting bets now!
            </Typography>
            <Button
              variant="contained"
              className={classes.betButton}
              onClick={handleCreateBet}
            >
              Create Bet
            </Button>
          </Box>
        )}
        {!betting.isLoading ? leagues.map((item) => (
          <BetListCard
            onClick={() => handleNext(item.type, item)}
            key={item.name}
            {...getLeagueInfo(item)}
          />
        )) : (<Loading />)}
      </Box>
      {parlay?.list.length > 0 && (
        <ParlayCart parlay={parlay} />
      )}
    </Page>
  );
};

Bets.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Bets);
