import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Box, Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import FrontDriverLicenseImage from 'assets/images/kyc/front_driver_license.png';
import FrontGovernmentIDImage from 'assets/images/kyc/front_id.png';
import FrontPassportImage from 'assets/images/kyc/passport.png';

import CommonStyle from 'styles/common';

import { mitekPageState } from 'utils/mitek';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: '2',
  },
  mainTitle: {
    font: '18px SofiaPro-SemiBold',
    textAlign: 'center',
  },
  description: {
    font: '13px SofiaPro',
    color: 'grey',
  },
  mainImage: {
    width: '80%',
    display: 'block',
    margin: '30px auto',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
  stepsWizard: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px auto',
  },
  stepsWizardBackgroundBar: {
    position: 'absolute',
    width: '98%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#AEAEAE',
    zIndex: '-1',
  },
  stepsWizardBackgroundBarActive: {
    position: 'absolute',
    width: '0%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#29ced3',
    zIndex: '-1',
  },
  stepsNumber: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#AEAEAE',
    color: '#808080',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  stepsNumberActive: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#29ced3',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  manualModeBox: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: palette.themeColor,
    left: '0',
    top: '0',
    padding: '1rem',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
}));

const MitekStep1_1 = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const [submitted, setSubmitted] = useState(false);
  const [manualMode, setManualMode] = useState(false);
  const [manualSubmitted, setManualSubmitted] = useState(false);
  const [nextScreen, setNextScreen] = useState('/mitek_step1_2');

  const handleSubmit = async () => {
    try {
      setSubmitted(true);

      if (mitekPageState.session.document_type == 'passport') {
        setNextScreen('/mitek_step2');
      }

      mitekPageState.auto_capture(mitekPageState.getDocumentType('front'), (result) => {
        // on complete
        mitekPageState.session.front = result;
        history.push(nextScreen);
      }, () => {
        setManualMode(true);

        // on failure
        // mitekPageState.session.front = result;
        // history.push(nextScreen);
        // mitekPageState.manual_capture(mitekPageState.getDocumentType('front'), (result) => {
        //   mitekPageState.session.front = result;
        //   history.push(nextScreen);
        // }, (result) => {
        //   mitekPageState.session.front = result;
        //   history.push(nextScreen);
        // });
      });
    } catch (e) {
    }
  };

  const handleManualModeSubmit = async() => {
    try {
      setManualSubmitted(true);

      mitekPageState.manual_capture(mitekPageState.getDocumentType('front'), (result) => {
        mitekPageState.session.front = result;
        history.push(nextScreen);
      }, (result) => {
        mitekPageState.session.front = result;
        history.push(nextScreen);
      });
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/mitek_select" />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography component="h3" className={classes.title}>
            STEP 2
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box mt={2} mb={2}>
            <Typography className={classes.mainTitle}>
              Capture
              { mitekPageState.session.document_type == 'driving' && ' Front of Driver\'s License'}
              { mitekPageState.session.document_type == 'id' && ' Front of Government ID'}
              { mitekPageState.session.document_type == 'passport' && ' Passport'}
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            { mitekPageState.session.document_type == 'driving' && (
              <img src={FrontDriverLicenseImage} className={classes.mainImage} alt="front of driver license" />
            )}
            { mitekPageState.session.document_type == 'id' && (
              <img src={FrontGovernmentIDImage} className={classes.mainImage} alt="front of government id" />
            )}
            { mitekPageState.session.document_type == 'passport' && (
              <img src={FrontPassportImage} className={classes.mainImage} alt="passport" />
            )}
          </Box>
          <Box>
            <Typography className={classes.subtitle}>
              • Use dark background.
              <br/>
              • Get all 4 corners within the frame.
              <br/>
              • Make sure lighting is good.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box className={classes.stepsWizard}>
            <Box className={classes.stepsWizardBackgroundBar}></Box>
            <Box className={classes.stepsWizardBackgroundBarActive}></Box>
            <Box className={classes.stepsNumberActive}>
              1
            </Box>
            <Box className={classes.stepsNumber}>
              2
            </Box>
            <Box className={classes.stepsNumber}>
              3
            </Box>
          </Box>
          <OutlineButton type="submit" disabled={submitted} onClick={handleSubmit}>
            Next
          </OutlineButton>
        </Box>
        { manualMode && (
          <Box className={classes.manualModeBox} display="flex" flexDirection="column">
            <OutlineButton type="submit" disabled={manualSubmitted} onClick={handleManualModeSubmit}>
              Manual capture
            </OutlineButton>
          </Box>
        )}
      </Box>
    </Page>
  );
};

MitekStep1_1.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MitekStep1_1);
