import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Button from 'components/Buttons/WhiteButton';
import ZenDialog from 'components/Dialog/ZenDialog';
import ParlayCart from 'components/Parlay/ParlayCart';

import { useParlay } from 'hooks/redux/parlay';
import { useSelectedLeague, useSetGamesDispatch, useSetIntervalsDispatch } from 'hooks/redux/bet';
import commonStyle from 'styles/common';

const navItems = [
  {
    path: '/bets/upcoming-games',
    intervals: ['single_game', 'multiple_games'],
    label: 'Upcoming Games',
  },
  {
    path: '/bets/all',
    intervals: ['season'],
    label: 'Season (Futures)',
  },
  {
    path: '/bets/all',
    intervals: ['single_game', 'multiple_games', 'season'],
    label: 'All Bets',
  },
];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  subtitle: {
    textAlign: 'center',
    font: '12px SofiaPro-SemiBold',
    color: '#08B0AA',
    marginBottom: 21,
    marginTop: 25,
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
  betsBtn: {
    '& button': {
      color: `${palette.secondaryColor} !important`,
      background: palette.cardColor,
    },
  },
}));

const infoDialogTitle = 'Which bets do you want to see?';

const TimePeriod = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const parlay = useParlay();
  const setIntervals = useSetIntervalsDispatch();
  const setGames = useSetGamesDispatch();
  const league = useSelectedLeague();

  const handleClickInfo = () => {
    setInfoDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setInfoDialogOpen(false);
  };

  const infoMessage = () => `Choose how you to want to view ${league?.name} bets. `
      + 'If you select Upcoming Games, you\'ll be able to view one or multiple games. '
      + 'Season/Futures are bets that take an entire season to play out and will be shown for the current or upcoming season. '
      + 'All Bets will show you everything under the ZenSports sun. 😎';

  const handleNext = useCallback((nav) => {
    setIntervals(nav.intervals);
    setGames([]);
    history.push(nav.path);
  }, [setIntervals, setGames, history]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Typography component="p" className={classes.subtitle}>
          {league?.name || ''}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Select Which Bets To View
          </Typography>
          <InfoOutlinedIcon className={classes.icon} onClick={handleClickInfo} />
          { infoDialogOpen && <ZenDialog title={infoDialogTitle} message={infoMessage()} cta="Got It!" onClose={handleCloseDialog} /> }
        </Box>
        <Box display="flex" flexDirection="column" className={classes.betsBtn}>
          {navItems.map((navItem) => (
            <Button
              key={navItem.label}
              onClick={() => handleNext(navItem)}
            >
              {navItem.label}
            </Button>
          ))}
        </Box>
      </Box>
      {parlay?.list.length > 0 && (
        <ParlayCart parlay={parlay} />
      )}
    </Page>
  );
};

TimePeriod.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(TimePeriod);
