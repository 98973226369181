import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import { formatCurrency } from 'utils/currency';
import NoPrizesImage from 'assets/images/icon/proof-of-work.png';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px -10px 8px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    fontSize: '21px',
    letterSpacing: -0.3,
    marginTop: 5,
    color: theme.palette.secondaryColor,
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    fontSize: '17px',
    color: theme.palette.secondaryColor,
  },
  feeContent: {
    color: '#00dde1',
  },
  line: {
    background: '#8d8d8d',
    height: '1px',
    margin: '18px 0 15px',
  },
  noPrizesImage: {
    width: '54px',
    height: 'auto',
    margin: '20px auto',
    display: 'block',
  },
  noPrizesText: {
    color: theme.palette.secondaryColor,
  },
}));

const PrizeMoney = ({
  payouts, currency, isDirector,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.root}>
        {
          payouts?.length > 0 ? payouts.map((item) => (
            <Box key={item.place}>
              <Box className={classes.detailsContainer}>
                <Typography className={classes.content}>
                  {item.place}
                </Typography>
                <Typography className={classes.feeContent}>
                  {formatCurrency(item.amount, currency, true)}
                </Typography>
              </Box>
              <Box className={classes.line} />
            </Box>
          ))
            : (
              <Box>
                <img src={NoPrizesImage} alt="NoPrizes" className={classes.noPrizesImage} />
                <Typography align="center" className={classes.noPrizesText}>
                  {
                    isDirector
                      ? 'You have not allocated any prize money.'
                      : 'The prize money has not been set by the tournament director.'
                  }
                </Typography>
              </Box>
            )
        }
      </Box>
    </Box>
  );
};

PrizeMoney.defaultProps = {
  isDirector: false,
  payouts: [],
  currency: {},
};

PrizeMoney.propTypes = {
  currency: PropTypes.object,
  payouts: PropTypes.array,
  isDirector: PropTypes.bool,
};

export default PrizeMoney;
