import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import commonStyles from 'styles/common';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import SelectButton from 'components/Buttons/SelectButton';

import {
  useGetTournamentQuery, useSetTournamentQueryDispatch, useUpdateTournamentDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  stepLine: {
    height: '3px',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    margin: '-10px -10px 10px',
    width: '40%',
  },
  root: {
    padding: '0 17px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  reset: {
    color: '#101451',
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.1,
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.3,
  },
  selectedButton: {
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedFormatButton: {
    width: '100%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedHistoryButton: {
    width: '50%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  amount: {
    borderBottom: `1px solid ${theme.palette.secondaryColor}`,
  },
  minMax: {
    font: '16px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.1,
    '& input': {
      textAlign: 'center',
    },
  },
  withdrawAddress: {
    color: theme.palette.primaryColor,
    font: '15px SofiaPro',
    textAlign: 'center',
    borderBottom: '1px solid #CCCCCC',
    '& input': {
      textAlign: 'center',
    },
  },
}));

const TournamentFormat = ({ history, edit }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data = useGetTournamentQuery();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const updateTournament = useUpdateTournamentDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const [form, setForm] = useState(data);

  const handleNext = async () => {
    try {
      setTournamentQuery(form);
      if (edit) {
        await updateTournament(data.id, {
          tournament: form,
        });
        history.push(`/more/play/tournament/view/${data.id}/edit`);
      } else {
        history.push('/more/play/tournament/create/date-time');
      }
    } catch (e) {
      const message = getErrorMessage(e);
      openErrorModal({ title: 'Oops', subtitle: message });
    }
  };

  const onHandleFormat = (name) => {
    setForm({
      ...form,
      format: name,
    });
  };

  const onHandleType = (name) => {
    setForm({
      ...form,
      weight: name,
    });
  };

  useEffect(() => {
    if (!data.format && !data.weight) {
      setForm({
        ...data,
        format: 'single_elimination_no_buyback',
        weight: 'open',
      });
    }
    if (edit) {
      setForm(data);
    }
  }, [data, edit]);

  return (
    <Box>
      <Box className={classes.stepLine} />
      <Box className={classes.root}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <BackButton color={classes.backBtn} />
          </Box>
          <Box textAlign="center" mb={4} mt={2}>
            <Typography className={commonClasses.subTitle}>
              Step 2
            </Typography>
            <Typography variant="h3" className={classes.title}>
              Tournament Format
            </Typography>
          </Box>
          <Typography className={classes.topic}>Format</Typography>
          <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
            <SelectButton
              className={classes.selectedHistoryButton}
              selected={form.format === 'single_elimination_no_buyback'}
              onClick={() => onHandleFormat('single_elimination_no_buyback')}
            >
              Single Elimination
            </SelectButton>
            <SelectButton
              className={classes.selectedHistoryButton}
              selected={form.format === 'double_elimination'}
              onClick={() => onHandleFormat('double_elimination')}
            >
              Double Elimination
            </SelectButton>
          </Box>
          <Typography className={classes.topic}>Type</Typography>
          <Box display="flex" justifyContent="space-between" mt={1} mb={4}>
            <SelectButton
              className={classes.selectedButton}
              selected={form.weight === 'open'}
              onClick={() => onHandleType('open')}
            >
              Open/Scratch
            </SelectButton>
            <SelectButton
              className={classes.selectedButton}
              selected={form.weight === 'handicap'}
              onClick={() => onHandleType('handicap')}
            >
              Handicap
            </SelectButton>
            <SelectButton
              className={classes.selectedButton}
              selected={form.weight === 'semi_handicap'}
              onClick={() => onHandleType('semi_handicap')}
            >
              Semi-Handicap
            </SelectButton>
          </Box>
        </Box>
        <Box>
          <OutlineButton onClick={handleNext}>
            {
              edit ? 'Save' : 'Next'
            }
          </OutlineButton>
        </Box>
      </Box>
    </Box>
  );
};
TournamentFormat.defaultProps = {
  edit: false,
};

TournamentFormat.propTypes = {
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default withRouter(TournamentFormat);
