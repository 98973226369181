import algoliasearch from 'algoliasearch';
import config from 'utils/config';

const { algoliaAppId, algoliaApiKey } = config;
const clientOptions = config.env === 'local' ? {
  hosts: [{
    url: 'localhost:3001',
    protocol: 'http',
  }],
} : {};

const client = algoliasearch(algoliaAppId, algoliaApiKey, clientOptions);

const search = (indexName, query, options) => {
  const index = client.initIndex(indexName);
  const { raw, ...rest } = options;
  return index.search(query, {
    cacheable: false,
    timeout: 100,
    ...rest,
  })
    .then((response) => {
      if (raw) {
        return response;
      }
      return response.hits;
    })
    .catch((err) => Promise.reject(err?.response?.data));
};

export default {
  search,
};
