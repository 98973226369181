import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import MatchNode from 'components/Play/Tournament/View/MatchNode';

import { useGetBracketsDetails } from 'hooks/redux/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 10px',
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 5,
  },
  switchIcon: {
    paddingRight: 5,
  },
  treeBox: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
  },
  roundOneBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 15,
    marginLeft: 15,
  },
  roundTwoBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 15,
    height: '100%',
    justifyContent: 'space-around',
  },
  roundFinalBox: {
  },
}));

const Brackets = ({ match }) => {
  const classes = useStyles();
  const { id } = match.params;
  const bracketDetails = useGetBracketsDetails(id);
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Bracket
          </Typography>
          <Typography component="h3" className={classes.subtitle}>
            Manage Your Tournament Bracket
          </Typography>
        </Box>
        <Box className={classes.treeBox}>
          <Box className={classes.roundOneBox}>
            {
              bracketDetails?.matches?.map((round, index) => {
                if (index < 4) {
                  return (
                    <MatchNode
                      player1={round.users?.[0]}
                      player2={round.users?.[1]}
                      winnerId={round.winner_id}
                      id={round.tournament_id}
                      matchId={round.id}
                      key={round.id}
                    />
                  );
                }
                return null;
              })
            }
          </Box>
          <Box className={classes.roundTwoBox}>
            {
              bracketDetails?.matches?.map((round, index) => {
                if ((index >= 4) && (index < bracketDetails.matches.length - 1)) {
                  return (
                    <MatchNode
                      player1={round?.users?.[0]}
                      player2={round?.users?.[1]}
                      winnerId={round.winner_id}
                      id={round?.tournament_id}
                      matchId={round?.id}
                      key={round?.id}
                    />
                  );
                }
                return null;
              })
            }
          </Box>
          <Box className={classes.roundFinalBox}>
            {
              bracketDetails?.matches?.map((round, index) => {
                if (index === bracketDetails.matches.length - 1) {
                  return (
                    <MatchNode
                      player1={round?.users?.[0]}
                      player2={round?.users?.[1]}
                      winnerId={round.winner_id}
                      id={round?.tournament_id}
                      matchId={round?.id}
                      key={round?.id}
                    />
                  );
                }
                return null;
              })
            }
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

Brackets.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Brackets);
