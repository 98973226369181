import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Avatar from 'components/Avatar';
import NoUsersImage from 'assets/images/icon/stellar.png';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '13px 0 10px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    fontSize: '18px',
    color: theme.palette.secondaryColor,
  },
  viewAllText: {
    color: '#52d4e3',
    fontSize: '16px',
  },
  usersContainer: {
    overflow: 'auto',
    marginBottom: 20,
  },
  userNameText: {
    color: '#ffff',
    fontWeight: 400,
  },
  playersBox: {
    height: 170,
    width: '100px',
    borderRadius: '8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: 10,
  },
  noUsersImage: {
    width: '60px',
    height: 'auto',
    margin: '20px auto',
    display: 'block',
  },
  noUsersText: {
    color: theme.palette.secondaryColor,
  },
}));

const RegisteredUsers = ({
  users, isDirector, history, id,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.titleContainer}>
        <Box className={classes.title}>
          Registered Players
        </Box>
        {users?.length > 0 && (
          <Typography
            className={classes.viewAllText}
            onClick={() => history.push(`/more/play/tournament/view/${id}/registered-users`)}
          >
            View all
          </Typography>
        )}
      </Box>
      <Box className={classes.usersContainer}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            width: users?.length > 0
              ? (users.length) * 130
              : '100%',
          }}
        >
          {
            users?.length > 0
              ? users.map((user) => (
                <Box
                  key={user.id}
                  className={classes.playersBox}
                  style={{ backgroundImage: `url('${user.cover_url}')` }}
                >
                  <Avatar src={user.profile_url} size="35px" />
                  <Typography className={classes.userNameText}>
                    {`${user.first_name} ${user.last_name}`}
                  </Typography>
                </Box>
              ))
              : (
                <Box>
                  <img src={NoUsersImage} alt="NoUsers" className={classes.noUsersImage} />
                  <Typography align="center" className={classes.noUsersText}>
                    {
                      isDirector
                        ? 'There are currently no registered players. Share the tournament to recruit more players.'
                        : 'There are currently no registered players. Share the tournament with your friends who might '
                        + 'be interested in registering for this tournament.'
                    }
                  </Typography>
                </Box>
              )
          }
        </Box>
      </Box>
    </>
  );
};

RegisteredUsers.defaultProps = {
  isDirector: false,
  users: [],
};

RegisteredUsers.propTypes = {
  id: PropTypes.number.isRequired,
  users: PropTypes.array,
  isDirector: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(RegisteredUsers);
