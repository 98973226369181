import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import Chart from 'components/Chart';
import Loading from 'components/Loading/Box';
import sportsIcon from 'assets/images/rewards/sports_token.png';
import rewardsCheck from 'assets/images/rewards/rewards-check.png';
import BackButton from 'components/Buttons/BackButton';
// import cupLogo from 'assets/images/rewards/cup-logo.png';
// import lowerFees from 'assets/images/rewards/lower-fees.png';
import cashBack from 'assets/images/rewards/cash-back.png';
import withdrawBonus from 'assets/images/rewards/gift-bonus.png';
import referralBonuses from 'assets/images/rewards/referral_bonuses.svg';
import LogoMarkImage from 'assets/images/rewards/logo-mark.png';
import { dateMYOptions } from 'utils/constants';

import FacebookIconImage from 'assets/images/social/facebook.png';
import TwitterIconImage from 'assets/images/social/twitter.png';
import TelegramIconImage from 'assets/images/social/telegram.png';
import LinkedinIconImage from 'assets/images/social/linkedin.png';

import {
  useRewardStatus,
  // useAuthIsLoading,
} from 'hooks/redux/user';
import { getBetChartInfo } from 'utils/betting';
import commonStyle from 'styles/common';

const socials = [
  {
    link: 'https://www.facebook.com/TheZenSports',
    icon: FacebookIconImage,
  },
  {
    link: 'https://twitter.com/zensports',
    icon: TwitterIconImage,
  },
  {
    link: 'https://www.linkedin.com/company/zensports',
    icon: LinkedinIconImage,
  },
  {
    link: 'https://t.me/thezensports',
    icon: TelegramIconImage,
  },
];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '14px SofiaPro-Bold',
    color: '#29CED3',
    textTransform: 'capitalize',
  },
  iconImg: {
    margin: '25px 0 10px 0',
  },
  sportsIconImg: {
    width: '45px',
    height: '45px',
    margin: '25px 0 10px 0',
  },
  subheader: {
    font: '18px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    margin: '17px -15px 7px',
    color: '#AEAEAE',
    font: '13px SofiaPro',
  },
  subtitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  cardTitleBoxContainer: {
    background: palette.cardColor,
  },
  contentCard: {
    background: palette.cardColor,
    margin: '10px 5px',
    padding: '0',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  contentChartCard: {
    margin: '10px 5px',
    padding: '0',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  cardTitleBox: {
    color: palette.secondaryColor,
    '& img': {
      float: 'left',
      margin: '20px 15px 0px 15px',
    },
  },
  cardBody: {
    clear: 'both',
    font: '13px SofiaPro',
    margin: '15px',
    overflow: 'auto',
    '& p': {
      color: palette.secondaryColor,
    },
  },
  chartBox: {
    '& .highcharts-container': {
      height: '290px !important',
    },
    '& .highcharts-background': {
      fill: palette.chartCardColor,
      height: '400px',
    },
  },
  cardBodyEmphasis: {
    color: '#29CED3',
    fontWeight: 'bold',
  },
  cardTitle: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-medium',
    float: 'left',
  },
  cardTitle2: {
    color: palette.secondaryColor,
    font: '18px SofiaPro-medium',
    float: 'left',
  },
  cardMessage: {
    color: '#aeaeae !important',
  },
  cardMessage2: {
    color: '#aeaeae !important',
    font: '16px SofiaPro-medium',
    margin: 0,
    lineHeight: 1.2,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 20px',
    borderRadius: '5px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  walletImage: {
    height: '50px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: '#FFFFFF',
    minHeight: 40,
    color: '#29CDD4',
    font: '16px SofiaPro-Bold',
  },
  rewardsTable: {
    width: '100%',
    borderCollapse: 'collapse',
    '& th': {
      background: palette.tableHeaderColor,
      fontSize: '14px',
      padding: '10px 5px',
      color: palette.secondaryColor,
      width: '50%',
    },
    '& td': {
      fontSize: '14px',
      color: palette.secondaryColor,
      padding: '10px 5px',
      textAlign: 'center',
      borderBottom: '1px solid #ccc',
    },
  },
  clearfix: {
    overflow: 'auto',
  },
  floatBtn: {
    float: 'left',
    margin: '10px',
  },
  pillMenu: {
    display: 'block',
    listStyle: 'none',
    background: palette.pillMenuBgColor,
    width: '266px',
    overflow: 'auto',
    borderRadius: '24px',
    margin: '0 auto 25px auto',
    padding: '2px;',
    '& li a': {
      float: 'left',
      color: palette.pillInactiveColor,
      padding: '15px 20px',
      borderRadius: '25px',
    },
    '& li a.active': {
      color: palette.secondaryColor,
      background: palette.pillActiveBgColor,
    },
  },
  logoMark: {
    backgroundImage: `url(${LogoMarkImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '13px 18px 10px',
    borderRadius: '5px',
    marginBottom: '8px',
    flexDirection: 'column',
    marginTop: '25px',
    '& p': {
      color: '#fff',
    },
  },
  logoTitle: {
    font: '20px SofiaPro-Bold',
    color: '#ffffff',
    textAlign: 'center',
  },
  filledButton: {
    background: 'linear-gradient(45deg, #298EDB 0%, #29D2D3 100%)',
    boxShadow: '0 6px 30px 0 rgba(0,214,219,0.8), 0 3px 10px 0 rgba(0,0,0,0.15)',
    minHeight: '50px',
    marginBottom: '10px',
    width: '100%',
    borderRadius: '10px',
    border: 'none',
    color: palette.secondaryColor,
    font: '16px SofiaPro-Medium',
  },
  chartTitle: {
    textAlign: 'center',
    color: '#aeaeae',
    margin: 0,
    font: '13px SofiaPro-Medium',
  },
  followOnSocialMedia: {
    textAlign: 'center',
    fontSize: '14px',
  },
  socialList: {
    display: 'flex',
  },
  socialItem: {
    padding: '0 10px',
  },
  socialIcon: {
    width: '40px',
  },
}));

const Rewards = () => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [openTab, setOpenTab] = useState('rewards');

  const reward = useRewardStatus();
  // const isAuthLoading = useAuthIsLoading();

  const handleTabClick = (e) => {
    setOpenTab(e.target.id);
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.clearfix}>
          <div className={classes.floatBtn}>
            <BackButton color={commonClasses.backBtn} />
          </div>
          <ul className={classes.pillMenu}>
            <li>
              <a id="rewards" className={(openTab === 'rewards') ? 'active' : ''} onClick={handleTabClick}>
                My Rewards
              </a>
            </li>
            <li>
              <a id="howRewards" className={(openTab === 'howRewards') ? 'active' : ''} onClick={handleTabClick}>
                How It Works
              </a>
            </li>
          </ul>
        </Box>
        <Box style={{ marginBottom: '0px' }} textAlign="center" mb={4} xs={12}>
          <Typography component="h3" className={classes.title}>
            ZenSports Rewards Program
          </Typography>
        </Box>
      </Box>
      {openTab === 'rewards' ? (
        <>
          <Box textAlign="center" mb={4} xs={12}>
            <img src={sportsIcon} alt="SPORTS Icon" className={classes.sportsIconImg} />
            <Typography component="h4" className={classes.subheader}>
              Earn the following rewards when betting with ZenSports
            </Typography>
          </Box>
          <Box className={classes.contentChartCard}>
            <Box className={classes.cardTitleBoxContainer}>
              <Box className={classes.cardTitleBox}>
                <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
                <h4 className={classes.cardTitle}>Cash Back Bonuses</h4>
              </Box>
              <Box className={classes.cardBody}>
                <p className={classes.cardMessage}>
                  Earn cash back based on your ZenSports betting volume each month!
                </p>
              </Box>
            </Box>
            <p className={classes.chartTitle}>
              Earning Period -
              {` ${new Date().toLocaleString('en-US', dateMYOptions)}`}
            </p>
            {reward && Object.keys(reward).length ? (
              <Box mt={-3} className={classes.chartBox}>
                <Chart {...getBetChartInfo(reward, 'taker')} />
              </Box>
            ) : (
              <Loading />
            )}
            <Box className={classes.cardTitleBoxContainer}>
              <table className={classes.rewardsTable}>
                <thead>
                  <tr>
                    <th>Betting Volume</th>
                    <th>Cash Back Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$0 - $999</td>
                    <td>0.00%</td>
                  </tr>
                  <tr>
                    <td>$1,000 - $2,499</td>
                    <td>0.25%</td>
                  </tr>
                  <tr>
                    <td>$2,500 - $4,999</td>
                    <td>0.50%</td>
                  </tr>
                  <tr>
                    <td>$5,000 - $9,999</td>
                    <td>1.00%</td>
                  </tr>
                  <tr>
                    <td>$10,000+</td>
                    <td>2.00%</td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box className={classes.cardTitleBoxContainer}>
              <Box className={classes.cardBody}>
                <p className={classes.cardMessage}>
                  All cash back payouts will automatically be issued to your account on the 1st of the month based
                  on the prior month’s betting volume.
                  <span aria-label="emoji" role="img">😎</span>
                </p>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle}>Infrequent Withdrawals Bonus</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage}>
                Keep your funds within ZenSports instead of withdrawing them, and we’ll give you a bonus
                every month based on your betting volume!
              </p>
            </Box>
            <table className={classes.rewardsTable}>
              <thead>
                <tr>
                  <th>Withdrawals per Month</th>
                  <th>Bonus Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0</td>
                  <td>1.00%</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>0.25%</td>
                </tr>
                <tr>
                  <td>2+</td>
                  <td>0.00%</td>
                </tr>
              </tbody>
            </table>
            <Box className={classes.cardBody}>
              <p className={classes.cardBodyEmphasis}>
                So far this month you’ve made
                <b>
                  {` ${reward?.withdrawals} `}
                </b>
                withdrawals.
              </p>
              <p className={classes.cardMessage}>
                All bonuses for making infrequesnt withdrawals will automatically be issued to your account on the
                1st of the month based on the prior month’s betting volume and withdrawal counts.
                <span aria-label="emoji" role="img">😎</span>
              </p>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.logoMark}>
            <Typography variant="h1" className={classes.logoTitle}>
              Welcome to the ZenSports Rewards Program!
            </Typography>
            <p>
              As a valuable ZenSports customer, you’re eligible to earn discounts,
              cash back, and bonuses when you bet within our app.
            </p>
            <p>
              Check out the current ways that you can earn rewards below. Be sure to check back
              here often, as well as on our social media channels, as we’ll be offering new rewards opportunities
              all the time!
            </p>
            <Box mt={3} className={classes.followOnSocialMedia}>
              <p>
                Follow us on Social Media
              </p>
            </Box>
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <Box className={classes.socialList}>
                {
                  socials.map((social) => (
                    <Box key={social.link} className={classes.socialItem}>
                      <a href={social.link} target="_blank" rel="noreferrer">
                        <img src={social.icon} alt="icon" className={classes.socialIcon} />
                      </a>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={cashBack} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Cash Back Bonuses</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                Cha-ching! Earn cash back every single month when your betting volume reaches certain tiers.
                The amount of cash back bonuses that you can earn is
                <strong> unlimited</strong>
                , so the more you bet, the more you earn! Plus, you can withdraw the bonus funds immediately
                if you’d like (no rollover requirement). Check out the My Rewards tab for the exact
                cash back tiers.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={withdrawBonus} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Infrequent Withdrawals Bonus</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                We’re not done giving away free money. When you make infrequent withdrawals and
                decide to keep your funds within your ZenSports account, you’ll receive a bonus
                each month. The fewer withdrawals you make, the larger the bonus. The amount of
                cash back bonuses that you can earn is
                <strong> unlimited</strong>
                , so the more you bet, the more you earn!
                Plus, you can withdraw the bonus funds immediately if you’d like (no rollover requirement)
                Check out the My Rewards tab for the exact Infrequent Withdrawals Bonus tiers.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={referralBonuses} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Referral Bonuses</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                Introduce friends, family, and others in your network to ZenSports, and you’ll earn
                a 3% Commission on their betting volume for the first six months that they’re a customer.
                And when they sign up with your Referral Code, they’ll receive an unlimited Bonus equal
                to 5% of their betting volume during the first 15 days that they have a ZenSports account.
                It’s a win-win! Click on More → Referral Bonuses to get started in referring others.
              </p>
            </Box>
          </Box>
        </>
      )}
    </Page>
  );
};

export default withRouter(Rewards);
