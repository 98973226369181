/**
 *
 * @param params
 * @returns {string}
 */
export const getFilterQuery = (params) => {
  const {
    mSport,
    singleEliminationNoBuyback,
    singleEliminationWithBuyback,
    doubleElimination,
    open,
    handiCap,
    semiHandiCap,
    currentHistory,
  } = params;

  let query = '(id > -1)';

  if (mSport != null && mSport !== '') {
    query = `${query} AND (game_type_display:"${mSport}")`;
  }

  const formatList = [];
  if (singleEliminationNoBuyback) {
    formatList.push('single_elimination_no_buyback');
  }
  if (singleEliminationWithBuyback) {
    formatList.push('single_elimination_with_buyback');
  }
  if (doubleElimination) {
    formatList.push('double_elimination');
  }
  if (formatList.length) {
    query = `${query} AND (`;
    query += formatList.map((format) => `format:"${format}"`).join(' OR ');
    query = `${query})`;
  }

  const weightList = [];
  if (open) {
    weightList.push('Open/Scratch');
  }
  if (handiCap) {
    weightList.push('Handicap');
  }
  if (semiHandiCap) {
    weightList.push('Semi-Handicap');
  }
  if (weightList.length) {
    query = `${query} AND (`;
    query += weightList.map((weight) => `weight_display:"${weight}"`).join(' OR ');
    query = `${query})`;
  }

  if (currentHistory) {
    query = `${query} AND completed:false`;
  } else {
    query = `${query} AND completed:true`;
  }

  return query;
};

export const sortCompetitions = (competitions, options) => {
  if (options.filter.currentHistory) {
    return competitions.sort(
      (a, b) => {
        const aDate = a.date ? new Date(a.date) : new Date();
        const bDate = b.date ? new Date(b.date) : new Date();
        return aDate - bDate;
      },
    );
  }
  return competitions.sort(
    (a, b) => {
      const aDate = a.date ? new Date(a.date) : new Date();
      const bDate = b.date ? new Date(b.date) : new Date();
      return bDate - aDate;
    },
  );
};
