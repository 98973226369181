import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Box, Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import FrontDocumentImage from 'assets/images/kyc/front_driver_license.png';
import BackDocumentImage from 'assets/images/kyc/back_driver_license.png';
import SelfieImage from 'assets/images/kyc/selfie.png';

import CommonStyle from 'styles/common';

import Loading from 'components/Loading/Box';
import mitekService from 'services/mitek.service';
import { mitekPageState } from 'utils/mitek';

import {
  useOpenSuccessModalDispatch,
  useOpenErrorModalDispatch,
} from 'hooks/redux/modal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: '2',
  },
  mainTitle: {
    font: '18px SofiaPro-SemiBold',
  },
  description: {
    font: '13px SofiaPro',
    color: 'grey',
  },
  mainImage: {
    width: '80%',
    display: 'block',
    margin: '30px auto',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
  stepsWizard: {
    position: 'relative',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px auto',
  },
  stepsWizardBackgroundBar: {
    position: 'absolute',
    width: '98%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#AEAEAE',
    zIndex: '-1',
  },
  stepsWizardBackgroundBarActive: {
    position: 'absolute',
    width: '98%',
    height: '3px',
    top: '14px',
    left: '1%',
    background: '#29ced3',
    zIndex: '-1',
  },
  stepsNumber: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#AEAEAE',
    color: '#808080',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  stepsNumberActive: {
    display: 'block',
    width: '32px',
    height: '32px',
    lineHeight: '36px',
    textAlign: 'center',
    borderRadius: '50%',
    background: '#29ced3',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  previewBox: {
    display: 'flex',
    alignItems: 'center',
  },
  previewImage: {
    maxHeight: '105px',
    objectFit: 'contain',
  },
}));

const MitekReview = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const openErrorModal = useOpenErrorModalDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitted(true);

      const attempBody = {
        document_type: mitekPageState.session.document_type,
        encoded_document_image_back: FrontDocumentImage,
        encoded_document_image_front: BackDocumentImage,
        encoded_selfie_image: SelfieImage,
        provider: 'mitek',
      };
      if (mitekPageState.session.back) {
        if (mitekPageState.session.back.response.docType === 'PDF417_BARCODE' && typeof mitekPageState.session.back.response.code !== 'undefined') {
          attempBody.encoded_document_image_back = mitekPageState.session.back.response.code;
        } else {
          attempBody.encoded_document_image_back = mitekPageState.session.back.response.imageData || mitekPageState.session.back.response.failedImage;
        }
      }
      if (mitekPageState.session.front) {
        attempBody.encoded_document_image_front = mitekPageState.session.front.response.imageData || mitekPageState.session.front.response.failedImage;
      }
      if (mitekPageState.session.selfie) {
        attempBody.encoded_selfie_image = mitekPageState.session.selfie.response.imageData || mitekPageState.session.selfie.response.failedImage;
      }
      attempBody.encoded_document_image_back = btoa(attempBody.encoded_document_image_back);
      attempBody.encoded_document_image_front = btoa(attempBody.encoded_document_image_front);
      attempBody.encoded_selfie_image = btoa(attempBody.encoded_selfie_image);
      const response = await mitekService.attempt(attempBody);

      setSubmitted(false);

      mitekPageState.session.submitted = true;

      openSuccessModal({
        title: 'Success!',
        subtitle: 'We\'ve received all of your KYC documentation and it will take anywhere from a few minutes to a few hours to verify this information. We will send you a push notification and email once your KYC status has been successfully verified (or if it has failed).',
        buttonText: 'Got it',
        nextPath: '/funds',
      });

    } catch (e) {
      openErrorModal({
        title: 'Oops!',
        subtitle: 'There was an issue with some of the documentation that you submitted. Please make sure that all of your documentation is completely visible, has good lighting, doesn\'t have any corners being cut off, and try submitting again.',
        buttonText: 'Re-do KYC Verification',
        callback: () => {
          history.push('/mitek_select');
        },
      });
    }
  };

  const startAgain = async () => {
    history.push('/mitek_select');
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/mitek_select" />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography component="h3" className={classes.title}>
            REVIEW
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box mt={2} mb={2}>
            <Typography className={classes.mainTitle}>
              Great job! Click Submit when you are ready to verify.
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Typography className={classes.subtitle}>
              Front
            </Typography>
            <Box className={classes.previewBox}>
              <img src={mitekPageState.session.front && (mitekPageState.session.front.response.imageData || mitekPageState.session.front.response.failedImage) || FrontDocumentImage} className={classes.previewImage} alt="id-verification" />
            </Box>
          </Box>
          {
            mitekPageState.session.document_type != 'passport' && (
              <Box mt={2} mb={2}>
                <Typography className={classes.subtitle}>
                  Back
                </Typography>
                <Box className={classes.previewBox}>
                  <img src={mitekPageState.session.back && (mitekPageState.session.back.response.imageData || mitekPageState.session.back.response.failedImage) || BackDocumentImage} className={classes.previewImage} alt="id-verification" />
                </Box>
              </Box>
            )
          }
          <Box mt={2} mb={2}>
            <Typography className={classes.subtitle}>
              Selfie
            </Typography>
            <Box className={classes.previewBox}>
              <img src={mitekPageState.session.selfie && (mitekPageState.session.selfie.response.imageData || mitekPageState.session.selfie.response.failedImage) || SelfieImage} className={classes.previewImage} alt="id-verification" />
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box className={classes.stepsWizard}>
            <Box className={classes.stepsWizardBackgroundBar} />
            <Box className={classes.stepsWizardBackgroundBarActive} />
            <Box className={classes.stepsNumberActive}>
              1
            </Box>
            <Box className={classes.stepsNumberActive}>
              2
            </Box>
            <Box className={classes.stepsNumberActive}>
              3
            </Box>
          </Box>
          <OutlineButton type="submit" disabled={submitted} onClick={handleSubmit}>
            Submit
          </OutlineButton>
          {submitted && (<Loading />)}
        </Box>
      </Box>
    </Page>
  );
};

MitekReview.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MitekReview);
