import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Box, Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import SuccessImage from 'assets/images/kyc/success.png';
import ErrorImage from 'assets/images/kyc/error.png';
import FailedImage from 'assets/images/kyc/failed.png';

import CommonStyle from 'styles/common';

import mitekService from 'services/mitek.service';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: '2',
  },
  mainTitle: {
    font: '18px SofiaPro-SemiBold',
    textAlign: 'center',
  },
  description: {
    font: '13px SofiaPro',
    color: 'grey',
  },
  mainImage: {
    width: '25%',
    display: 'block',
    margin: '30px auto',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
}));

const MitekStatus = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const [kycStatus, setKycStatus] = useState('');

  useEffect(async () => {
    try {
      const response = await mitekService.status();
      // setKycStatus(response.status);
    } catch(e) {
      console.log(e);
    }
  }, []);

  const handleSubmitError = async () => {
    try {
      history.push('/funds');
    } catch (e) {
    }
  };

  const handleSubmit = async () => {
    try {
      history.push('/funds');
    } catch (e) {
    }
  };

  if (kycStatus === 'failed') {
    return (
      <Page>
        <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
          <Box>
            <BackButton color={commonClasses.backBtn} page="/funds" />
          </Box>
          <Box textAlign="center" mb={3}>
            <Typography component="h3" className={classes.title}>
              Verification Error
            </Typography>
          </Box>
          <Box className={classes.content}>
            <Box mt={2} mb={2}>
              <Typography className={classes.mainTitle}>
              Oops! There was an issue with some of the documentation that you submitted. Please make sure that all of your documentation is completely visible, has good lighting, doesn't have any corners being cut off, and try submitting again.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <img src={ErrorImage} className={classes.mainImage} alt="id-verification" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <OutlineButton type="submit" onClick={handleSubmitError}>
              Re-do KYC Verification
            </OutlineButton>
          </Box>
        </Box>
      </Page>
    );
  }

  if (kycStatus === 'fraud') {
    return (
      <Page>
        <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
          <Box>
            <BackButton color={commonClasses.backBtn} page="/funds" />
          </Box>
          <Box textAlign="center" mb={3}>
            <Typography component="h3" className={classes.title}>
              Verification Failed
            </Typography>
          </Box>
          <Box className={classes.content}>
            <Box mt={2} mb={2}>
              <Typography className={classes.mainTitle}>
                Unfortunately, we were unable to successfully verify your KYC information. You will be unable to use ZenSports at this time.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <img src={FailedImage} className={classes.mainImage} alt="id-verification" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <OutlineButton type="submit" onClick={handleSubmit}>
              Next
            </OutlineButton>
          </Box>
        </Box>
      </Page>
    );
  }

  if (kycStatus === 'passed') {
    return (
      <Page>
        <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
          <Box>
            <BackButton color={commonClasses.backBtn} page="/funds" />
          </Box>
          <Box textAlign="center" mb={3}>
            <Typography component="h3" className={classes.title}>
              VERIFICATION COMPLETE
            </Typography>
          </Box>
          <Box className={classes.content}>
            <Box mt={2} mb={2}>
              <Typography className={classes.mainTitle}>
                Thank you!<br/>
                Your verification is complete.
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <img src={SuccessImage} className={classes.mainImage} alt="id-verification" />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <OutlineButton type="submit" onClick={handleSubmit}>
              Next
            </OutlineButton>
          </Box>
        </Box>
      </Page>
    );
  }

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box className={classes.content} />
        <Box display="flex" flexDirection="column">
          <OutlineButton type="submit" onClick={handleSubmit}>
            Next
          </OutlineButton>
        </Box>
      </Box>
    </Page>
  );
};

MitekStatus.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MitekStatus);
