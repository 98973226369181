import { combineReducers } from 'redux';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';

import auth from './auth';
import theme from './theme';
import modal from './modal';
import country from './country';
import bet from './bet';
import kyc from './kyc';
import notification from './notification';
import transaction from './transaction';
import currency from './currency';
import account from './account';
import funds from './funds';
import referral from './referral';
import exchange from './exchange';
import play from './play';
import parlay from './parlay';

export const key = 'zensports_0022';

const betConfig = {
  key: `${key}:bet`,
  storage: localforage,
  blacklist: [
    'isLoading',
    'list',
    'single',
    'leagues',
    'games',
    'schedules',
    'objects',
  ],
};

const playConfig = {
  key: `${key}:play`,
  storage: localforage,
  blacklist: [
    'isLoading',
    'list',
  ],
};

const currencyConfig = {
  key: `${key}:currency`,
  storage: localforage,
  blacklist: [
    'isLoading',
    'message',
    'selected_currency_symbol',
  ],
};

const modalConfig = {
  key: `${key}:modal`,
  storage: localforage,
  blacklist: [
    'showSuccessModal',
    'showErrorModal',
    'showConfirmModal',
    'successModal',
    'confirmModal',
    'errorModal',
  ],
};

const exchangeConfig = {
  key: `${key}:exchange`,
  storage: localforage,
  blacklist: [
    'isLoading',
    'isMarketChangeLoading',
    'isOrdersLoading',
    'isCreateOrderLoading',
    'isCancelOrderLoading',
  ],
};

const accountConfig = {
  key: `${key}:account`,
  storage: localforage,
  blacklist: [
    'isLoading',
  ],
};

const kycConfig = {
  key: `${key}:kyc`,
  storage: localforage,
  blacklist: [
    'isLoading',
    'resourceToken',
    'user',
    'documentation',
  ],
};

const parlayConfig = {
  key: `${key}:parlay`,
  storage: localforage,
  blackList: ['isLoading', 'resultList', 'excludedList'],
};

export default combineReducers({
  auth,
  theme,
  country,
  funds,
  notification,
  transaction,
  referral,
  kyc: persistReducer(kycConfig, kyc),
  account: persistReducer(accountConfig, account),
  exchange: persistReducer(exchangeConfig, exchange),
  bet: persistReducer(betConfig, bet),
  play: persistReducer(playConfig, play),
  parlay: persistReducer(parlayConfig, parlay),
  currency: persistReducer(currencyConfig, currency),
  modal: persistReducer(modalConfig, modal),
});
