import React from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'react-highcharts';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import cup from 'assets/images/rewards/sports_token_black.png';

const useStyles = makeStyles({
  chartWrapper: {
    position: 'relative',
    marginTop: '-15px',
    height: '300px',
  },
  chart: {
    zIndex: -1,
    position: 'absolute',
    font: '16px SofiaPro-Medium',
    width: '100%',
    height: '270px',
    '& .highcharts-container': {
      height: '270px !important',
    },
  },
  chartCup: {
    width: '40px',
  },
  volume: {
    marginTop: '10px',
    color: '#aeaeae',
    font: '13px SofiaPro-Medium',
  },
  betting: {
    marginTop: '8px',
    color: '#29CDD4',
    font: '30px SofiaPro-Bold',
  },
  sports: {
    color: '#29CDD4',
    font: '13px SofiaPro-Bold',
  },
  chartRange: {
    textAlign: 'center',
    color: '#aeaeae',
    font: '13px SofiaPro-Medium',
  },
});

const Chart = ({
  volume, max, min, title, tier,
}) => {
  const classes = useStyles();
  const chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '400px',
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'middle',
      y: 0,
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        height: '200px',
        startAngle: -100,
        endAngle: 100,
      },
    },
    credits: {
      text: '',
    },
    series: [{
      type: 'pie',
      name: ' ',
      innerSize: '88%',
      data: [
        {
          name: '',
          y: volume - min,
          color: {
            linearGradient: {
              x1: 0, y1: 0, x2: 0, y2: 1,
            },
            stops: [
              [0, '#29D2D3'],
              [1, '#298EDA'],
            ],
          },
          dataLabels: {
            enabled: false,
          },
        },
        tier !== 4 && (
          {
            name: '',
            y: max - volume,
            color: '#edf1f5',
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enable: false,
            },
          }
        ),
      ],
    }],
  };

  return (
    <Box className={classes.chartWrapper}>
      <Box className={classes.chart}>
        <ReactHighcharts config={chartOptions || {}} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box mt={15} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <img src={cup} alt="cup" className={classes.chartCup} />
          <Typography className={classes.volume}>
            {title}
          </Typography>
          <Typography className={classes.betting}>
            $
            { Number(volume).toLocaleString('en-US') }
          </Typography>
        </Box>
        <Box mt={1} display="flex" justifyContent="space-between" pl={1} pr={1}>
          <Typography className={classes.chartRange}>
            $
            { Number(min).toLocaleString('en-US') }
          </Typography>
          <Typography className={classes.chartRange}>
            {
              tier < 4 && (
                <>
                  $
                  {Number(max).toLocaleString('en-US')}
                </>
              )
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Chart.defaultProps = {
  volume: 0,
  max: 0,
  min: 0,
  title: '',
  tier: 0,
};

Chart.propTypes = {
  volume: PropTypes.number,
  max: PropTypes.number,
  tier: PropTypes.number,
  min: PropTypes.number,
  title: PropTypes.string,
};

export default Chart;
