import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Avatar from 'components/Avatar';

import CrownImage from 'assets/images/play/crown.png';
import { useCompletedMatches } from 'hooks/redux/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 10px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '10px',
    width: '100%',
    marginRight: 20,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  matchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    position: 'relative',
  },
  completedContainer: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  matchText: {
    color: theme.palette.secondaryColor,
    fontSize: '12px',
    marginTop: 5,
  },
  completedText: {
    color: '#a8b2bd',
    fontSize: '12px',
    marginTop: 5,
    textTransform: 'capitalize',
  },
  roundText: {
    textAlign: 'center',
    color: theme.palette.secondaryColor,
    marginTop: 10,
  },
  crownImg: {
    position: 'absolute',
    top: '-17px',
  },
}));

const CompletedMatches = ({ match, history }) => {
  const classes = useStyles();
  const { id } = match.params;
  const completedMatchDetails = useCompletedMatches(id);
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Completed Matches
          </Typography>
        </Box>
        <Box>
          {
          completedMatchDetails?.length > 0 && completedMatchDetails.map((item) => (
            <Box
              key={item.id}
              className={`cardOverride ${classes.card}`}
              onClick={() => history.push(`/more/play/tournament/view/${id}/match-details/${item.id}`)}
            >
              <Typography className={classes.roundText}>
                {item?.place}
              </Typography>
              <Box className={classes.contentContainer}>
                <Box key={item?.users?.[0].id} className={classes.matchContainer}>
                  {
                    item.winner_id === item?.users?.[0].id
                      && <img src={CrownImage} alt="" className={classes.crownImg} />
                  }
                  <Avatar src={item?.users?.[0].profile_url} size="50px" />
                  <Typography className={classes.matchText}>
                    {item?.users?.[0].name}
                  </Typography>
                </Box>
                <Box className={classes.completedContainer}>
                  <Typography className={classes.completedText}>
                    {item?.status}
                  </Typography>
                </Box>
                <Box key={item?.users?.[1].id} className={classes.matchContainer}>
                  {
                    item.winner_id === item?.users?.[1].id
                      && <img src={CrownImage} alt="" className={classes.crownImg} />
                  }
                  <Avatar src={item?.users?.[1].profile_url} size="50px" />
                  <Typography className={classes.matchText}>
                    {item?.users?.[1].name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))
          }
        </Box>
      </Box>
    </Page>
  );
};

CompletedMatches.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CompletedMatches);
