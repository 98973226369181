import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
  setUserFavoritesAction,
  getUserAction,
  getUserRewardAction,
  getUserLocationAction,
  getUserAccessToken,
  installAction,
  unInstallAction,
  setLocationAction,
  updateUserAction,
} from 'store/actions/auth';

export const useAuthAccessToken = () => getUserAccessToken();

export const useAuthIsLoading = () => useSelector(({ auth: { isLoading } }) => isLoading);

export const useAuthUser = () => useSelector(({ auth: { user } }) => user);

export const useUserRestrictedStatus = () => useSelector(({ auth: { user } }) => !(user.betting_allowed && user.fantasy_allowed));

export const useUserRestrictedFeatures = () => useSelector(({ auth: { user } }) => user.restricted_features || []);

export const useUserRestrictedAccess = () => useSelector(({ auth: { user } }) => {
  const features = user.restricted_features || [];
  const restricted = {
    isBetOrFantasyVisible: true,
    isCryptoVisible: false,
    isPeerToPeer: true,
  };

  if (features.length) {
    restricted.isBetOrFantasyVisible = !['create_bets_visibility', 'create_fantasy_visibility'].some((item) => features.indexOf(item) >= 0);
    // restricted.isCryptoVisible = !(features.indexOf('cryptocurrency_visibility') >= 0);
    restricted.isPeerToPeer = !(features.indexOf('peer_to_peer_visibility') >= 0);
  }

  return restricted;
});

export const useUserFavorites = () => useSelector(({ auth: { user } }) => user.favorites_json);

export const useUserOddType = () => useSelector(({ auth: { user } }) => user.odds_display_type || 'american');

export const useSetFavorites = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setUserFavoritesAction(data));
};

export const useGetMe = () => {
  const dispatch = useDispatch();
  return (loading) => dispatch(getUserAction(loading));
};

export const useRewardStatus = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserRewardAction());
  }, [dispatch]);
  return useSelector(({ auth: { reward } }) => reward);
};

export const useUserLocation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserLocationAction());
  }, [dispatch]);
  return useSelector(({ auth: { location } }) => location || {});
};

export const useCheckNevadaLocation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserLocationAction());
  }, [dispatch]);
  return useSelector(({ auth: { location, user } }) => {
    const _location = location;
    if (_location?.ip_address && _location?.device) {
      const isNevada = (_location.ip_address.region?.name?.toLowerCase() === 'nevada' || _location.device.region?.name?.toLowerCase() === 'nevada');
      if (isNevada) {
        return user?.nevada_registered ? 'VERIFIED' : 'UNVERIFIED';
      }
    }
    return 'N/A';
  });
};

export const useUpdateUserLocation = () => {
  const dispatch = useDispatch();
  return (user) => {
    if (navigator.geolocation && user) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(updateUserAction({
          id: user.id,
          location: {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          },
        }));
        dispatch(setLocationAction({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        }));

        return true;
      }, () => {});
    }
    return false;
  };
};

export const useInstallDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(installAction());
};

export const useUnInstallDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(unInstallAction());
};
