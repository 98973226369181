import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import { usePlayIsLoading } from 'hooks/redux/play';
import CompetitionCard from './CompetitionCard';

const useStyles = makeStyles(() => ({
  vListWrapper: {
    flex: 1,
    height: '100vh',
  },
  emptyState: {
    textAlign: 'center',
    color: '#aeaeae',
    marginTop: '120px',
    lineHeight: '1.2',
  },
  competitionCard: {
    margin: '20px 0',
  },
}));

const CompetitionsVlist = ({ play }) => {
  const classes = useStyles();
  const isLoading = usePlayIsLoading();
  return (
    <Box className={classes.competitionCard}>
      <Box className={classes.vListWrapper}>
        {!isLoading ? (play.length > 0 ? (
          <VList
            rowCount={play.length}
            rowRender={(index) => (
              <>
                <CompetitionCard item={play[index]} />
              </>
            )}
          />
        ) : (
          <>
            <Typography className={classes.emptyState} variant="subtitle2">
              It doesn’t look like there are any play available right now.
            </Typography>
          </>
        )) : (<Loading />)}
      </Box>
    </Box>
  );
};

CompetitionsVlist.propTypes = {
  play: PropTypes.array.isRequired,
};

export default React.memo(CompetitionsVlist);
