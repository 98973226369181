import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';
import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import ZenDialog from 'components/Dialog/ZenDialog';
import BackButton from 'components/Buttons/BackButton';
import Competitions from 'components/Play/Competitions';

import CommonStyle from 'styles/common';

const comingSoonMessage = 'We are working on revamping these features. '
  + 'Email support@zensports.com and let us know about your interest in these features.';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 10px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: '10px 10px 0 10px',
    marginTop: '20px',
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  clearfix: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  floatBtn: {
    float: 'left',
    margin: '10px',
  },
  pillMenu: {
    listStyle: 'none',
    background: palette.pillMenuBgColor,
    borderRadius: '24px',
    margin: 'auto',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& li a': {
      float: 'left',
      fontSize: '12px',
      color: palette.pillInactiveColor,
      padding: '10px 15px',
      borderRadius: '25px',
    },
    '& li a.active': {
      color: palette.secondaryColor,
      background: palette.pillActiveBgColor,
    },
  },
}));

const Play = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [openTab, setOpenTab] = useState('competitions');
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);
  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };
  const handleTabClick = (e) => {
    setOpenTab(e.target.id);
  };
  const handleFilter = () => {
    history.push('/more/play/filter');
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.clearfix}>
          <div className={classes.floatBtn}>
            <BackButton color={commonClasses.backBtn} />
          </div>
          <ul className={classes.pillMenu}>
            <li>
              <a
                id="players"
                className={openTab === 'players' ? 'active' : ''}
                onClick={() => setComingSoonDialogOpen(true)}
              >
                Players
              </a>
            </li>
            <li>
              <a
                id="competitions"
                className={openTab === 'competitions' ? 'active' : ''}
                onClick={handleTabClick}
              >
                Competitions
              </a>
            </li>
            <li>
              <a
                id="facilities"
                className={openTab === 'facilities' ? 'active' : ''}
                onClick={() => setComingSoonDialogOpen(true)}
              >
                Facilities
              </a>
            </li>
          </ul>
          <TuneIcon className={classes.filterBtn} onClick={handleFilter} />
        </Box>
      </Box>
      {
        openTab === 'competitions' && <Competitions />
      }
      {comingSoonDialogOpen
        ? (
          <ZenDialog
            title="Feature Coming Soon!"
            message={comingSoonMessage}
            cta="Got It!"
            onClose={handleCloseComingSoonDialog}
          />
        ) : null}
    </Page>
  );
};

Play.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Play);
