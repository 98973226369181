import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import accountImage from 'assets/images/funds/account.png';
import depositImage from 'assets/images/funds/deposit.png';
import exchangeImage from 'assets/images/funds/exchange.png';
import rewardImage from 'assets/images/funds/sports_token.png';
import transactionImage from 'assets/images/funds/transaction.png';
import kycImage from 'assets/images/funds/kyc.png';
import withdrawImage from 'assets/images/funds/withdraw.png';

import {
  useMappedCurrencies,
  useSelectedCurrency,
  useSelectCurrencyDispatch,
} from 'hooks/redux/currency';
import { useAccountOverviewInfo } from 'hooks/redux/account';
import { useUserRestrictedAccess } from 'hooks/redux/user';
import { useKycVerified } from 'hooks/redux/kyc';
import { useFundsOverview } from 'hooks/redux/funds';

import ZenDialog from 'components/Dialog/ZenDialog';
import { getFormattedCurrency } from 'utils/currency';

import { comingSoonMessage } from 'utils/constants';

import { mitekPageState } from 'utils/mitek';

const navItems = [
  {
    path: '/funds/deposit',
    label: 'Deposit Funds',
    icon: depositImage,
    cryptoRestricted: false,
  },
  {
    path: '/funds/rewards',
    label: 'ZenSports Rewards',
    icon: rewardImage,
    cryptoRestricted: false,
  },
  {
    path: '/funds/exchange',
    label: 'Exchange',
    icon: exchangeImage,
    cryptoRestricted: true,
  },
  {
    path: '/funds/withdraw/:currency',
    label: 'Withdraw Funds',
    icon: withdrawImage,
    cryptoRestricted: false,
  },
  {
    path: '/mitek_start',
    label: 'KYC Verification',
    icon: kycImage,
    cryptoRestricted: false,
    kyc_menu: true,
  },
  {
    path: '/funds/transaction',
    label: 'Transactions List',
    icon: transactionImage,
    cryptoRestricted: false,
  },
  {
    path: '/funds/fiat-accounts',
    label: 'Manage Fiat Accounts',
    icon: accountImage,
    cryptoRestricted: false,
  },
];
const potentialBalanceDialogTitle = 'Potential Balance from Bets Outstanding';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '14px SofiaPro-Bold',
    color: '#29CED3',
    textTransform: 'capitalize',
  },
  navigation: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    padding: '10px 24px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
    position: 'relative',
    overflow: 'hidden',
  },
  navigationDisabled: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    padding: '10px 24px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
    position: 'relative',
    overflow: 'hidden',
    opacity: 0.5,
  },
  content: {
    textAlign: 'center',
    margin: '30px auto 25px auto',
    font: '15px SofiaPro-Regular',
    color: '#29CFD4',
    textDecoration: 'underline',
  },
  icon: {
    width: '45px',
    height: '45px',
    display: 'block',
  },
}));

const Funds = ({ history }) => {
  const classes = useStyles();
  const [params, setParams] = useState();
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const currencySymbol = useSelectedCurrency();
  const overview = useAccountOverviewInfo();
  const kycVerified = useKycVerified();
  const fundsOverview = useFundsOverview(params);
  const mappedCurrencies = useMappedCurrencies();
  const selectCurrency = useSelectCurrencyDispatch();
  const access = useUserRestrictedAccess();

  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };

  const handleNext = useCallback((e, path, navItem) => {
    if (navItem.kyc_menu && mitekPageState.session.submitted) {
      return;
    }
    e.target.classList.add('ripple');
    setTimeout(() => {
      e.target.classList.remove('ripple');
      if (path.includes('withdraw')) {
        history.push(`/funds/withdraw/${currencySymbol}`);
      } else if (path.includes('transaction')) {
        history.push(`/funds/transaction/${currencySymbol}`);
      } else if (path.includes('exchange')) {
        history.push('/funds/exchange');
      } else if (path.includes('fiat-accounts')) {
        if (kycVerified) {
          history.push(path);
        } else if (overview.status === 'new') {
          // window.location.href = '/kyc/account';
          history.push('/kyc/profile');
        } else if (overview.status === 'pending') {
          history.push('/kyc/status');
        }
      } else {
        history.push(path);
      }
    }, 100);
  }, [history, overview, kycVerified, currencySymbol]);

  const handlePotentialBalanceClick = () => {
    setInfoDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setInfoDialogOpen(false);
  };

  const infoMessage = () => {
    const sportsCurrency = mappedCurrencies.sports;
    const selectedCurrency = fundsOverview.currencies[0].currency;
    const outstandingBal = fundsOverview.currencies[0].bets_outstanding;
    const potentialWinBal = fundsOverview.currencies[0].potential_winnings;
    const totalPotentialWinBal = fundsOverview.currencies[0].total_potential_balance;
    const sportsRolloverBal = fundsOverview.currencies[0].sports_rollover;
    const freePlayBonusBal = fundsOverview.currencies[0].free_play_bonus;
    const betsOutstanding = getFormattedCurrency(
      outstandingBal,
      selectedCurrency,
    );
    const potentialWinning = getFormattedCurrency(
      potentialWinBal,
      selectedCurrency,
    );
    const totalPotentialWinning = getFormattedCurrency(
      totalPotentialWinBal,
      selectedCurrency,
    );
    const sportsRollover = getFormattedCurrency(
      sportsRolloverBal,
      sportsCurrency,
      'crypto',
    );
    const freePlayBonus = getFormattedCurrency(
      freePlayBonusBal,
      sportsCurrency,
      'crypto',
    );
    return `Bets Outstanding : ${betsOutstanding}
    Potential Winnings : ${potentialWinning}
    Total Potential Balance : ${totalPotentialWinning}
    Free Play Bonus : ${freePlayBonus}
    Roll Over Requirement Amount : ${sportsRollover}`;
  };

  useEffect(() => {
    if (!access.isCryptoVisible) {
      selectCurrency('usd');
      setParams({
        token: 'usd',
      });
    } else if (currencySymbol) {
      setParams({
        token: currencySymbol,
      });
    }
    // eslint-disable-next-line
  }, [currencySymbol, access.isCryptoVisible]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            FUNDS
          </Typography>
        </Box>
        <Box>
          <CurrencyDropdown showExchangeRate changeable={access.isCryptoVisible} />
          {navItems.map((navItem) => (
            (!navItem.cryptoRestricted || access.isCryptoVisible) && (
              <Box
                onClick={(e) => handleNext(e, navItem.path, navItem)}
                className={navItem.kyc_menu && mitekPageState.session.submitted ? classes.navigationDisabled : classes.navigation}
                key={navItem.path}
                display="flex"
                alignItems="center"
              >
                <Box mr={3}>
                  <img src={navItem.icon} alt="navigation" className={classes.icon} />
                </Box>
                {navItem.label}
              </Box>
            )
          ))}
        </Box>
        <Typography className={classes.content} onClick={handlePotentialBalanceClick}>
          View potential Balance from Bets Outstanding
        </Typography>
        {infoDialogOpen && (
          <ZenDialog
            title={potentialBalanceDialogTitle}
            message={infoMessage()}
            cta="CLOSE"
            onClose={handleCloseDialog}
            customDialog="potentialBalance"
          />
        )}
        {comingSoonDialogOpen && (
          <ZenDialog
            title="Feature Coming Soon!"
            message={comingSoonMessage}
            cta="Close"
            customCta="Download iOS/Android App"
            customCtaLink="zensports.com"
            onClose={handleCloseComingSoonDialog}
          />
        )}
      </Box>
    </Page>
  );
};

Funds.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Funds);
