import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Avatar from 'components/Avatar';

import { useGetTournamentDetail, useGetTwitchUrl } from 'hooks/redux/play';
import { formattedDate } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  sportLabel: {
    marginTop: 15,
    marginBottom: 15,
    fontWeight: 'bold',
    color: theme.palette.secondaryColor,
    fontSize: '16px',
  },
  playersBox: {
    height: 'auto',
    width: '32%',
    borderRadius: '8px 0px 0px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '10px',
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: 10,
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
  },
  userNameText: {
    color: '#ffff',
    fontWeight: 400,
    fontSize: '12px',
    paddingLeft: 4,
  },
  streamBox: {
    background: theme.palette.cardColor,
    borderRadius: '8px',
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    marginBottom: 10,
  },
  streamContent: {
    height: '100%',
    width: '65%',
    padding: '10px 13px 10px 0',
  },
  dateText: {
    color: '#52d4e3',
    fontSize: '12px',
    textAlign: 'end',
  },
  streamText: {
    fontSize: '14px',
    marginTop: 5,
    color: theme.palette.secondaryColor,
  },
  typesText: {
    color: '#b5b5b5',
    fontSize: '12px',
    marginTop: 4,
  },
}));

const RegisterUserForTournament = ({ match }) => {
  const classes = useStyles();

  const { id } = match.params;
  const details = useGetTournamentDetail(id);
  const twitchChannels = useGetTwitchUrl(id);

  const getCoverUrl = useCallback((userId) => {
    if (details.users) {
      return details.users.find((user) => user.id === userId)?.cover_url || null;
    }
    return null;
  }, [details]);

  const handleClickStream = useCallback((channelUrl) => {
    if (channelUrl) {
      window.open(`https://www.twitch.tv/${channelUrl}`, '_blank');
    }
  }, []);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Streams
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.sportLabel}>Player Streams</Typography>
        </Box>
        {
          twitchChannels?.length > 0
            ? twitchChannels.map((user) => (
              <Box
                className={classes.streamBox}
                onClick={() => handleClickStream(user.twitch_channel_url)}
                key={user.id}
              >
                <Box
                  className={classes.playersBox}
                  style={{ backgroundImage: `url(${getCoverUrl(user.id)})` }}
                >
                  <Box className={classes.watchBox}>
                    <Avatar src={user.profile_url} size="25px" />
                    <Typography className={classes.userNameText}>
                      {`${user.first_name} ${user.last_name}`}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.streamContent}>
                  <Typography className={classes.dateText}>
                    {formattedDate(details?.date_iso8601)}
                  </Typography>
                  <Typography className={classes.streamText}>
                    {`Watch ${user.first_name} ${user.last_name}'s stream`}
                  </Typography>
                  <Typography className={classes.typesText}>
                    {`Tournament ${details?.format_display}`}
                    {` ${details?.weight_display}`}
                  </Typography>
                </Box>
              </Box>
            )) : (
              <Typography align="center">
                There are no player streams.
              </Typography>
            )
        }
      </Box>
    </Page>
  );
};

RegisterUserForTournament.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(RegisterUserForTournament);
