import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import resultIconImage from 'assets/images/icon/result.png';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  accept: {
    borderTop: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Black',
    paddingBottom: '8px',
  },
  amount: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
    textAlign: 'center',
    paddingBottom: '8px',
  },
  total: {
    borderBottom: '1px solid #cccccc',
  },
  results: {
    backgroundColor: '#d3f2f5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResult: {
    color: '#A4A4A4',
    font: '14px SofiaPro-Bold',
  },
  resultImage: {
    width: '60px',
  },
  card: {
    backgroundColor: palette.cardColor,
    width: '90%',
    borderRadius: '6px',
    padding: '10px',
  },
  cardTitle: {
    textAlign: 'center',
  },
  content: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-Bold',
    textAlign: 'center',
  },
  textArea: {
    padding: 10,
    borderRadius: '5px',
    color: palette.secondaryColor,
    backgroundColor: '#eeeeee',
  },
}));

const MarketView = ({ betInfo }) => {
  const classes = useStyles();

  const getResultText = () => {
    if (betInfo.status === 'expired' || betInfo.status === 'proposed') {
      return (
        <Box mt={3} textAlign="center">
          <Typography className={classes.noResult}>
            {`
            This bet is no longer acceptable because the event has already started,
            and doesn't have any results to display because there weren't any Takers.
            Please go back and filter by "Current and Acceptable" to view bets that are still acceptable.
            `}
          </Typography>
        </Box>
      );
    }
    if (betInfo.status === 'partially_accepted' && betInfo.isClosed) {
      return (
        <Box mt={3} textAlign="center">
          <Typography className={classes.noResult}>
            This bet was partially filled and has been closed out by the Maker, so it can no longer be accepted by any new Takers.
          </Typography>
        </Box>
      );
    }
    if (betInfo.status === 'fully_accepted') {
      return (
        <Box mt={3} textAlign="center">
          <Typography className={classes.noResult}>
            This bet has already been 100% filled/accepted by other Takers, so it can no longer be accepted by any new Takers.
          </Typography>
        </Box>
      );
    }
  };

  const getResultDetails = () => {
    if (
      betInfo.betType.object_type === 'team' && betInfo.betType.interval === 'single_game'
      && (betInfo.awayTeamPoints || betInfo.homeTeamPoints)
    ) {
      return (
        <Grid item xs={6} sm={6}>
          <Typography variant="subtitle2" className={classes.cardTitle}>
            Team Scores
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            { `${betInfo.homeTeamName} ${betInfo.homeTeamPoints}` }
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            { `${betInfo.awayTeamName} ${betInfo.awayTeamPoints}` }
          </Typography>
        </Grid>
      );
    }
    if (betInfo.resultDetailsList.length > 0) {
      return (
        <Grid item xs={6} sm={6}>
          <Typography variant="subtitle2" className={classes.cardTitle}>
            {betInfo.resultDetailsList[0].title}
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            {betInfo.resultDetailsList[0].details || 'Not Submitted Yet'}
          </Typography>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pb={1}
        pt={1}
        className={classes.accept}
      >
        <Typography variant="subtitle2" className={classes.smallTitle}>
          Filled/Accepted
        </Typography>
        <Typography variant="subtitle2" className={classes.amount}>
          {betInfo.formattedFilledPercentageAmount}
        </Typography>
        <Typography variant="subtitle2" className={classes.amount}>
          {betInfo.closedPercentageText}
        </Typography>
      </Box>
      <Box className={classes.results} p={2}>
        <img alt="bet" src={resultIconImage} className={classes.resultImage} />
        <Typography variant="subtitle2" className={classes.subtitle}>Bet results</Typography>
        {betInfo.result && (
          <>
            <Box display="flex" mt={3} className={classes.card}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle2" className={classes.cardTitle}>
                  Winner of Bet
                </Typography>
                <Typography variant="subtitle2" className={classes.content}>
                  {betInfo.winnerDescription}
                </Typography>
              </Grid>
              { getResultDetails() }
              {betInfo.resultDetailsList.length === 2 && (
                <Box item xs={12} sm={12}>
                  <Typography variant="subtitle2" className={classes.cardTitle}>
                    {betInfo.resultDetailsList[1].title}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {betInfo.resultDetailsList[1].details}
                  </Typography>
                </Box>
              )}
            </Box>
            {betInfo.result === 'win' && betInfo.isDisputedByMe && betInfo.disputeDetails.length > 0 && (
              <Box className={classes.card}>
                <Box className={classes.subtitle}>Dispute Details</Box>
                {betInfo.disputeDetails.map((detail) => (
                  <Box mt={1}>
                    {detail}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
        { getResultText() }
      </Box>
    </>
  );
};

MarketView.defaultProps = {
  betInfo: {},
};

MarketView.propTypes = {
  betInfo: PropTypes.object,
};

export default MarketView;
