import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import commonStyles from 'styles/common';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import InputBox from 'components/Form/InputBox';
import CoverPhoto from 'components/CoverPhoto';

import {
  useGetTournamentQuery, useSetTournamentQueryDispatch, useUpdateTournamentDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  stepLine: {
    height: '3px',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    margin: '-10px -10px 10px',
    width: '20%',
  },
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  form: {
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
  sportLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    color: theme.palette.secondaryColor,
    fontSize: '15px',
  },
  info: {
    boxShadow: '2px 2px 9px rgb(0 0 0 / 20%)',
    background: theme.palette.cardColor,
    border: 'none',
    borderRadius: 5,
    '& input': {
      fontSize: '18px',
    },
    '& div': {
      border: 'none',
      borderRadius: 10,
    },
  },
}));

const TournamentInfo = ({ history, edit }) => {
  const [coverUrl, setCoverUrl] = useState(null);
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data = useGetTournamentQuery();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const updateTournament = useUpdateTournamentDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  useEffect(() => {
    if (data && data.profile_url) {
      setCoverUrl(data.profile_url);
    }
  }, [data]);
  return (
    <>
      <Formik
        initialValues={{
          name: (data && data.name) || '',
          location: (data && data.location) || '',
          game_type: (data && data.game_type) || '',
          profile_url: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const query = {
              ...data,
              game_type: values.game_type,
              location: values.location,
              name: values.name,
              profile_url: coverUrl,
            };
            if (edit) {
              await updateTournament(data.id, {
                tournament: query,
              });
              setTournamentQuery(query);
              history.push(`/more/play/tournament/view/${data.id}/edit`);
            } else {
              setTournamentQuery(query);
              history.push('/more/play/tournament/create/format');
            }
          } catch (e) {
            const message = getErrorMessage(e);
            openErrorModal({ title: 'Oops', subtitle: message });
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Required'),
          location: Yup.string()
            .required('Required'),
          game_type: Yup.string()
            .required('Required'),
          profile_url: coverUrl === null ? Yup.string().required('Required') : null,
        })}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
            <Box className={classes.stepLine} />
            <Box className={classes.root}>
              <Box>
                <Box>
                  <BackButton color={classes.backBtn} />
                </Box>
                <Box textAlign="center" mb={4} mt={2}>
                  <Typography className={commonClasses.subTitle}>
                    Step 1
                  </Typography>
                  <Typography component="h3" className={classes.title}>
                    Tournament Information
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    label="Tournament Name"
                    className={commonClasses.textField}
                    placeholder="Enter The Name Here"
                    helperText=""
                    fullWidth
                    name="name"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                  {
                    (errors.name && touched.name) && (
                    <Typography className={commonClasses.error}>
                      {errors.name}
                    </Typography>
                    )
                  }
                  <CoverPhoto coverUrl={coverUrl} changeUrl={setCoverUrl} />
                  {
                    (errors.profile_url && touched.profile_url) && (
                    <Typography className={commonClasses.error}>
                      {errors.profile_url}
                    </Typography>
                    )
                  }
                  <TextField
                    label="Location"
                    className={commonClasses.textField}
                    placeholder="Enter Location Name"
                    helperText=""
                    fullWidth
                    name="location"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.location}
                  />
                  {
                    (errors.location && touched.location) && (
                    <Typography className={commonClasses.error}>
                      {errors.location}
                    </Typography>
                    )
                  }
                  <Typography className={classes.sportLabel}>Which Sport Are You Playing</Typography>
                  <Box className={classes.info} mt={1}>
                    <InputBox
                      label="Select Sport"
                      value={values.game_type}
                      name="game_type"
                      onClick={() => {
                        if (edit) {
                          const query = {
                            ...data,
                            game_type: values.game_type,
                            location: values.location,
                            name: values.name,
                            profile_url: coverUrl,
                          };
                          setTournamentQuery(query);
                          history.push(`/more/play/tournament/edit/${data.id}/information/select-sport`);
                        } else {
                          const query = {
                            ...values,
                            game_type: values.game_type,
                            location: values.location,
                            name: values.name,
                            profile_url: coverUrl,
                          };
                          setTournamentQuery(query);
                          history.push('/more/play/tournament/create/information/select-sport');
                        }
                      }}
                      format="outline"
                    />
                    {
                      errors.game_type && touched.game_type
                        ? (
                          <Typography className={commonClasses.error}>
                            {errors.game_type}
                          </Typography>
                        ) : null
                    }
                  </Box>
                </Box>
              </Box>
              <OutlineButton
                type="submit"
                style={{ margin: '20px 0' }}
                disabled={isSubmitting}
              >
                {
                  edit ? 'Save' : 'Next'
                }
              </OutlineButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

TournamentInfo.defaultProps = {
  edit: false,
};

TournamentInfo.propTypes = {
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default withRouter(TournamentInfo);
