import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Loading from 'components/Loading/Box';
import sportsIcon from 'assets/images/rewards/sports_token.png';
import LogoMarkImage from 'assets/images/rewards/white_no_text_logo.png';

import { useAuthUser } from 'hooks/redux/user';
import { useBonusesList, useReferralIsLoading } from 'hooks/redux/referral';
import { dateOptions } from 'utils/constants';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '12px SofiaPro',
    color: '#29CED3',
    textTransform: 'capitalize',
  },
  subtitle: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
    marginTop: '32px',
  },
  clearfix: {
    overflow: 'auto',
  },
  floatBtn: {
    float: 'left',
    margin: '10px',
  },
  tableWrapper: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    background: palette.cardColor,
    padding: '10px 10px',
    position: 'relative',
    overflow: 'hidden',
  },
  logoMark: {
    padding: '17px 0px',
    backgroundColor: 'rgb(20, 20, 61)',
    borderRadius: '5px',
    marginBottom: '8px',
    flexDirection: 'column',
    marginTop: '25px',
    position: 'relative',
    '& p': {
      color: '#fff',
    },
  },
  logoMarkImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    opacity: '0.2',
  },
  sportsIcon: {
    width: '45px',
    height: '45px',
  },
  thTitle: {
    color: palette.secondaryColor,
  },
  total: {
    color: 'white',
    font: '13px SofiaPro',
  },
  totalAmount: {
    color: '#32CD32',
    font: '30px SofiaPro-Bold',
    wordBreak: 'break-all',
  },
  bonusTable: {
    borderCollapse: 'collapse',
    '& th': {
      font: '11px SofiaPro-Bold',
      padding: '10px 5px',
      borderBottom: '1px solid #f5f7f9',
    },
    '& tr': {
      borderBottom: '1px solid #f5f7f9',
      '&:last-child': {
        border: 0,
      },
    },
    '& td': {
      fontSize: '13px',
      color: '#aeaeae',
      padding: '10px 5px',
      textAlign: 'center',
    },
  },
}));

const ViewBonus = () => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const user = useAuthUser();
  const bonusesList = useBonusesList(user.referral_code);
  const isLoading = useReferralIsLoading();
  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.clearfix}>
          <div className={classes.floatBtn}>
            <BackButton color={commonClasses.backBtn} />
          </div>
        </Box>
        <Box style={{ marginBottom: '0px', marginTop: '-32px' }} textAlign="center" mb={4} xs={12}>
          <Typography component="h3" className={classes.title}>
            View Bonuses
          </Typography>
          <Typography component="h3" className={classes.subtitle}>
            All numbers below are in USD
          </Typography>
          {!isLoading ? (
            <>
              <Box className={classes.logoMark}>
                <img src={LogoMarkImage} className={classes.logoMarkImage} alt="background" />
                <img src={sportsIcon} alt="sports" className={classes.sportsIcon} />
                <Box display="flex" mt={1}>
                  <Grid item xs={6} sm={6}>
                    <Typography component="h3" className={classes.total}>
                      Total Betting Volume
                    </Typography>
                    <Typography component="h3" className={classes.totalAmount}>
                      { Math.floor(Number(bonusesList.total_fees_paid) / 10 ** 4).toLocaleString('en-US') }
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography component="h3" className={classes.total}>
                      Total Commissions
                    </Typography>
                    <Typography component="h3" className={classes.totalAmount}>
                      { Math.floor(Number(bonusesList.total_commission) / 10 ** 4).toLocaleString('en-US') }
                    </Typography>
                  </Grid>
                </Box>
              </Box>
              <Box className={classes.tableWrapper} mt={3}>
                <table className={classes.bonusTable}>
                  <thead>
                    <tr>
                      <th className={classes.thTitle}>Referral</th>
                      <th className={classes.thTitle}>Sign-up Date</th>
                      <th className={classes.thTitle}>Betting Volume</th>
                      <th className={classes.thTitle}>Commissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(bonusesList.users && bonusesList.users.length > 0) && bonusesList.users.map((item) => (
                      <tr key={item.name}>
                        <td>{item.name}</td>
                        <td>{new Date(item.signup).toLocaleString('en-US', dateOptions)}</td>
                        <td>{ Math.floor(Number(item.total_fees_paid) / 10 ** 4).toLocaleString('en-US') }</td>
                        <td>{ Math.floor(Number(item.commission) / 10 ** 4).toLocaleString('en-US') }</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </>
          ) : (<Loading />)}
        </Box>
      </Box>
    </Page>
  );
};

ViewBonus.propTypes = {};

export default withRouter(ViewBonus);
