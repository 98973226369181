import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Box, Checkbox, Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import IDVerificationImage from 'assets/images/kyc/id-verification.png';

import CommonStyle from 'styles/common';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    paddingLeft: '0px',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: '2',
  },
  mainTitle: {
    font: '18px SofiaPro-SemiBold',
  },
  description: {
    font: '13px SofiaPro',
    color: 'grey',
  },
  mainImage: {
    width: '80%',
    display: 'block',
    margin: '30px auto',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
}));

const MitekStart = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const handleSubmit = async () => {
    try {
      history.push('/mitek_select');
    } catch (e) {
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography component="h3" className={classes.title}>
            ID Verification
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box mt={2} mb={2}>
            <img src={IDVerificationImage} className={classes.mainImage} alt="id-verification" />
          </Box>
          <Box mt={2} mb={2}>
            <Typography className={classes.mainTitle}>
              Let's verify your identity in 3 simple steps.
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.subtitle}>
              • Select document type & country of issue
              <br/>
              • Capture ID document
              <br/>
              • Capture Selfie
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <OutlineButton type="submit" onClick={handleSubmit}>
            Next
          </OutlineButton>
        </Box>
      </Box>
    </Page>
  );
};

MitekStart.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(MitekStart);
